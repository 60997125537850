import { useSelector } from "react-redux";

const AboutUsAyanair = () => {

    const LAN = useSelector(state => state.VisualData.LAN);

    switch(LAN){
        case "KU":
            return(
                <div>
                <p>
                  کۆمپانیای ئایان ئەیر ساڵی دامەزراندنی دەگەڕێتەوە بۆ ساڵی 2019 لە کوردستان دەبێتە یەکێک لە باشترین کۆمپانیا خزمەتگوزاریەکان لە کەرتی گەشت و گوزاردا .
                </p>
                <p>
                  بە خۆشحاڵیەوە لە ئێستادا پلان دادەنێێن کە بتوانین لە داهاتوویەکی نزیکدا سیستەمێک بۆ دانانی هەموو خزمەتگوزاریەکانی گەشت و گوزار و گەشتیاری تێیدا دابین بکەین ، سیستەمی ئایان ئەیر لە تەواو بووندایە و ماڵپەڕێکە بۆ خزمەتکردنی سەرجەم گەشتیارەکانمان، دەتوانیت لە ڕێگای ئەم سیستەمەوە  تکت و هۆتێل و پاکێجی گەشتیاری لە ڕێگای ئۆنلاینەوە لەسەرانسەری جیهان  بە دەست بخەیت ، وە دروستکردنی سیستەمێک تا خزمەت بۆ کۆمپانیا گەشتیاریەکان بکات .
                </p>
                <p>
                  خزمەتگوزارییەکانمان پێکدێت لە :
                </p>
                <ul className="bullet-list">
                  <li> بلیتی نێودەوڵەتی </li>
                  <li> بلیتی ناوخۆییەکان ( بە گەشتە ناوخۆییەکانی عێراق و ئێرانەوە ) </li>
                  <li> هۆتێلەکانی سەرتاسەری جیهان. </li>
                  <li> پاکێجی گەشتیاری </li>
                  <li> بیمەی تەندروستی </li>
                  <li> گواستنەوە لە فڕۆکەخانەکان </li>
                  <li> خزمەتگوزاری (سی آی پی )فڕۆکەخانەکان . </li>
                </ul>
              </div>                
            );

          case "AR":
            return(
              <div>
                <p>
                  تأسست شركة ئایەن ئایر فی عام 2019 وستكون واحدة من أفضل شركات الخدمات في قطاع السياحة في كردستان. نخطط حاليًا لتوفير نظام لجميع الخدمات السياحية في المستقبل القريب ، نظام إيان إير على وشك الانتهاء وهو موقع لخدمة جميع سياحنا، من خلال هذا النظام يمكنك الحصول على التذاكر والفنادق وباقات السفر عبر الإنترنت حول العالم، وإنشاء نظام لخدمة شركات السياحة. تأسست شركة ئایەن ئایر عام 2019 وستكون واحدة من أفضل شركات الخدمات في قطاع السياحة في كردستان.
                </p>
                <p>
                  ولحسن الحظ، نخطط حاليًا لتوفير نظام لجميع الخدمات السياحية في المستقبل القريب. نظام ئایەن ئایر على وشك الانتهاء وهو موقع لخدمة جميع سياحنا، من خلال هذا النظام يمكنك الحصول على التذاكر والفنادق وباقات السفر عبر الإنترنت حول العالم، وإنشاء نظام لخدمة شركات السياحة.
                </p>
                <p>تشمل خدماتنا ما يلي:</p>
                <ul className="bullet-list">
                  <li> التذاكر الدولية . </li>
                  <li> التذاكر الداخلية (بما في ذلك الرحلات الداخلية إلى العراق وإيران) </li>
                  <li> الفنادق حول العالم . </li>
                  <li> برامج سياحية </li>
                  <li> تأمين صحي </li>
                  <li> الإنتقالات في المطارات </li>
                  <li> خدمات CIP في المطار. </li>
                </ul>

              </div>
            )
        default:
            return (
                <div dir="ltr">
                    <p>
                        Ayan Air Company published in 2019 that become one of the best service companies in (Travelling and tourism) sector.
                    </p>
                    <p>
                    We planned for having a system to put all the ( Travelling and tourism) services in it therefore we have created (Ayan Air system) which is a (B2C) website for servicing all our customers throughout the world,and (B2B) website which is fully and friendly one that serve the travel agencies.
                    </p>

                    <p>
                    OUR SERVICES CONSISTS OF:
                    </p>
                    <ul className="bullet-list" dir="ltr">
                        <li>International tickets</li>
                        <li>Domestic tickets (including Iraq and Iran domestic flights)</li>
                        <li>Worldwide hotels</li>
                        <li>Package and tours</li>
                        <li>Car rental</li>
                        <li>Health insurance</li>
                        <li>Airport Transfer</li>
                        <li>CIP airport service</li>
                    </ul>
        
                </div>
            )
    }

}
export default AboutUsAyanair;