import FlightListOriginDestination from "./FlightListOriginDestination";

const FlightListItem = props => {
    return(
    <div className="new-flight-item">
    {props.model?.airItinerary?.originDestinationOptions.map((item,index) => <FlightListOriginDestination originDestination={item} key={index} />)}
    </div>
    )
}

export default FlightListItem;