import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../../scss/autocomplete.scss';
import subset from '../../image/autocomplete/subset.svg';
import location from '../../image/autocomplete/location.svg';
import { HotelApartment, LocationSvg, Location2Svg } from './icons';

const Autocomplete = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.defaultValue || null);
  const [selectedText, setSelectedText] = useState(props.defaultText || null);
  const [showlist, setShowlist] = useState(false);
  const [result, setResult] = useState([]);
  const [typingValue, setTypingValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [focusStatus, setFocusStatus] = useState(false);
  const [frequentDestinationsMode, setFrequentDestinationsMode] = useState(false);

  const inputRef = useRef();

  const clearValue = () => {
    setSelectedValue(null);
    setSelectedText(null);
    setTypingValue('');

    props.getValue(null, null, props.name, props.index);

    inputRef.current.focus();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(props.defaultValue || null);
    setSelectedText(props.defaultText || null);
  }, [props.defaultValue, props.defaultText]);

  const inputChangeHandler = e => {
    if (props.type === 'flight') {
      props.getValue(selectedValue, selectedText, props.name, props.index);
    }
    setSelectedValue(null);
    setSelectedText(null);
    setTypingValue(e.target.value);
  }

  let inputIsPersian = false;
  if (props.checkInputLang){
    const persianInput = /^[\u0600-\u06FF\s]+$/;
    if (persianInput.test(typingValue) || persianInput.test(selectedText)) {
      inputIsPersian = true;
    }
  }

  const fetchData = useCallback(
    (text) => {
      let Headers;
      if (props.checkInputLang){
        if(inputIsPersian){
          Headers = { ...props.header ,
            'Accept-Language': 'fa-IR'
          }
        }else{
          Headers = { ...props.header ,
            'Accept-Language': 'en-US'
          }
        }
      }else{
        Headers = { ...props.header };
      }
      axios({
        method: props.requestTypePost ? 'post':'get',
        url: `${props.url}?${props.param}=${text}`,
        headers: Headers,
      })
        .then((response) => {
          setLoading(false);
          let result = response.data.result;
          if (result.length !== 0) {
            setResult(result);
            setShowlist(true);
            setFrequentDestinationsMode(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [props.url, props.param,inputIsPersian]
  );

  useEffect(()=>{
    let fetchTimeout;
    if (typingValue.length >= props.min){
      setLoading(true);
      fetchTimeout = setTimeout(()=>fetchData(typingValue),500);
    }else{
      setLoading(false);
      setShowlist(false);
      setResult([]);
    }    
    return ()=>{clearTimeout(fetchTimeout)}
  },[typingValue,fetchData,props.min]);

  const selectItem = (item) => {
    if (props.type === 'flight') {
      setSelectedValue(item.Code);
      setSelectedText(item.Name);
      setShowlist(false);
      setResult([]);

      props.getValue(item.Code, item.Name, props.name, props.index);
    } else if (props.type === 'package') {
      setSelectedValue(item.code);
      setSelectedText(item.name);
      setShowlist(false);
      setResult([]);

      props.getValue(item.code, item.name, props.name, props.index);
    } else if (props.type === 'airline') {
      setSelectedValue(item.Code);
      setSelectedText(item.Name);
      setShowlist(false);
      setResult([]);
      props.getValue(item.Code, item.Name, props.name);
    } else if (props.type === 'domesticHotel') {
      setSelectedValue(item.id);
      setSelectedText(item.name);
      setShowlist(false);
      setResult([]);

      props.getValue(item.id, item.name, item.type);
    }    
    else {
      setSelectedValue(item.id);
      setSelectedText(item.name);
      setShowlist(false);
      setResult([]);

      props.getValue(item.id, item.name, item.typeId);
    }
    setFocusStatus(false);
  };
  const focusInput = (focus) => {
    if (props.frequentDestinations) {
      setFrequentDestinationsMode(true);
      setResult(props.frequentDestinations);
      setShowlist(true);
    }
    if (focus){
      setFocusStatus(true);
    }
  };
  const back = () => {
    setFocusStatus(false);
    setFrequentDestinationsMode(false);
    setResult([]);
    setShowlist(false);
  };
  const autocompleteElementRef = useRef();

  const handleClickOutside = (e) => {
    if (autocompleteElementRef.current && !autocompleteElementRef.current.contains(e.target)) {
      setShowlist(false);
    }
  };

  let suggestList = null;
  if (props.type === 'flight'){
    suggestList = <div className="suggestList">
      {frequentDestinationsMode && <h5 className="frequent-dest-heading">مسیرهای پرتردد</h5>}
      <ul>
      {result.map((item, index) => {
        return (
          <li key={index}>
            <div
              className="link"
              onClick={() => {
                selectItem(item);
              }}>
              <div>
                <span>
                  <LocationSvg className="autocomplete-list-icon" />
                </span>
                <span className="name">{item.Name}</span>
              </div>
              <div className="location">{item.ParentName}</div>
            </div>
          </li>
        );
      })}
      </ul>
    </div>
  }else if (props.type === 'package'){
    suggestList = <div className="suggestList">
      {frequentDestinationsMode && <h5 className="frequent-dest-heading">مسیرهای پرتردد</h5>}
      <ul>
        {result.map((item, index) => {
          return (
            <li key={index}>
              <div
                className="link"
                onClick={() => {
                  selectItem(item);
                }}>
                <div>
                  <span>
                    <img src={location} alt="airport" />
                  </span>
                  <span className="name">{item.name}</span>
                </div>
                <div className="location">{item.parentName}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  }
  else if (props.type === 'airline'){
    suggestList = <div className="suggestList">
    <ul>
      {result.map(airlineItem => {
        return (
          <li key={airlineItem.Id}>
            <div
              className="link"
              onClick={() => {selectItem(airlineItem);}}>
              <div>
                <span className="name">{airlineItem.Name}</span>
              </div>
            </div>
          </li>
        );
      })}      
    </ul>
  </div>
  }
  else if (props.type === 'domesticHotel'){
    suggestList = <div className={`suggestList`}>
    {/* {frequentDestinationsMode && <h5 className="frequent-dest-heading">مسیرهای پرتردد</h5>} */}
    <ul>
      {result.map((item, index) => {
        return (
          <li key={index}>
              <div
                className={`link ${item.typeId === 6 ? "blue" : ""}`}
                onClick={() => {selectItem(item);}}>
                <div>
                  {item.type === "City" ? (
                    <Location2Svg className="autocomplete-list-icon" />
                  ) : item.type === "Province" ? (
                    <LocationSvg className="autocomplete-list-icon" />
                  ):(
                    <HotelApartment className="autocomplete-list-icon" />
                  )}
                  <span className={`name ${(item.type === "City" || item.type === "Province") ? "important":""}`}>{item.name}</span>
                </div>
                <div className="location">{item.displayName}</div>
              </div>
          </li>
        );
      })}      
    </ul>
    </div>
  }
  else{
    suggestList = <div className="suggestList">
    {frequentDestinationsMode && <h5 className="frequent-dest-heading">مسیرهای پرتردد</h5>}
    <ul>
      {result.map((item, index) => {
        return (
          <li key={index}>

            {/* {item.children == null ? ( */}
            
              <div
                className={`link ${item.typeId === 6 ? "blue" : ""}`}
                onClick={() => {
                  selectItem(item);
                }}>
                <div>
                  <span>{item.typeId === 6 ? <HotelApartment className="autocomplete-list-icon" /> : <LocationSvg className="autocomplete-list-icon" /> }</span>
                  <span className="name">{item.name}</span>
                </div>
                <div className="location">{item.parentName}</div>
              </div>

            {/* ) : (
              <div className="parentLink">
                <div
                  className={`link ${item.typeId === 6 ? "blue" : ""}`}
                  onClick={() => {
                    selectItem(item);
                  }}>
                  <div>
                  <span>{item.typeId === 6 ? <HotelApartment className="autocomplete-list-icon" /> : <LocationSvg className="autocomplete-list-icon" /> }</span>
                    <span className="name">{item.name}</span>
                  </div>
                  <div className="location">{item.parentName}</div>
                </div>
                <ul>
                  {item.children.map((itemIN, indexIN) => (
                    <li
                      onClick={() => {
                        selectItem(itemIN);
                      }}
                      key={indexIN}>
                      <span className="icon">
                        <img src={subset} alt="hotel" />
                      </span>
                      <span className="name">{itemIN.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}

          </li>
        );
      })}      
    </ul>
    </div>
  }

  if (!showlist){
    suggestList = null;
  }
  const wrapperClassNames = ["autocompleteFeild"];
  const inputClassNames = ["form-input"];
  if (focusStatus){
    wrapperClassNames.push('is-focused');
  }
  if (!props.checkInputLang){
    inputClassNames.push("placeholder-right");
  }else if (typingValue.length || selectedText?.length){
    if (inputIsPersian){
      wrapperClassNames.push("input-is-fa");
      inputClassNames.push("fa-input");
    }else{
      wrapperClassNames.push("input-is-en");
      inputClassNames.push("en-input");
    }
    
  }

  return (
    <div ref={autocompleteElementRef} className={wrapperClassNames.join(" ")}>
      <div className="autocompleteText form-group no-margin-bottom">
        {loading && <div className={'loading-circle small'} />}
        <input
          ref={inputRef}
          className={inputClassNames.join(" ")}
          placeholder={props.placeholder}
          type="text"
          autoComplete="disabled"
          onFocus={()=>{focusInput(true)}}
          onChange={inputChangeHandler}
          value={selectedText || typingValue}
        />
        {selectedValue ? <span className="itours-icon gray-close-icon clear-autocomplete" onClick={clearValue} /> : null}
        {suggestList}
      </div>
      {focusStatus && (
        <button className={'close-autocomplete-btn'} onClick={back}>
          <span className={`itours-icon gray-close-icon icon-large`} />
        </button>
      )}
    </div>
  );
};

export default Autocomplete;
