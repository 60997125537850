import React, { useEffect, useState,useRef } from "react";
import queryString from "query-string";
import _ from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

import { Header, DomesticHotelApiUrls as HotelURL, ServerAddress } from "../../Enum/Urls";
import HotelNoImage from "../../image/default-hotel-image.svg";
import { setDomesticHotelFilter } from "../../Actions/DomesticHotelAction";
import Rating from "../../components/Common/Rating";
import { showError } from "../../Actions/VisualDataAction";
import danger from "../../image/danger.svg";
import LayoutConfig from "../../Config/layoutConfig";

import Page from "../../components/Common/Page";
import Loading from "../../components/Common/Loading";
import ShowMap from "../../components/Accomodation/showMap";
import Map from "../../components/Common/Map";
import HotelItem from "../../components/domesticHotel/DomesticHotelItem";
import DomesticHotelFilter from "../../components/domesticHotel/DomesticHotelFilter";
import DomesticHotelSearchForm from '../../components/domesticHotel/DomesticHotelSearchForm';
import { Skeleton } from "antd";
import FetchProgress from "../../components/Common/FetchProgress";

const DomesticHotelResult = props => {

  const parameters = queryString.parse(props.location.search);
  const checkIn = parameters.checkin?.split("T")[0] || moment().format("YYYY-MM-DD");
  const checkOut = parameters.checkout?.split("T")[0] || moment().add(1, "day").format("YYYY-MM-DD");
  const duration = moment(checkOut).diff(checkIn, 'days');

  const { Dictionary } = props;

  const [loading, setLoading] = useState(true);
  const [serachResponse, setSearchResponse] = useState();
  const [availabilityResponse, setAvailabilityResponse] = useState();
  const [rateResponse, setRateResponse] = useState();
  const [showfilter, setShowfilter] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [page, setPage] = useState([0, 9]);
  const [sortBy, setSortBy] = useState("priority");
  const [reverseSort, setReverseSort] = useState(false);

  const searchResultRef = useRef(); 

  let hotelIds;
  if (serachResponse) {
    hotelIds = serachResponse.Hotels.map(item => (item.HotelId));
  }

  const resetFiltersAndPagination = () => {
    props.changeFiltersHandle("reset");
    setPage([0,9]);
    setSortBy("priority");
    setReverseSort(false);
  }

  const sortOptions = [
    { name: "priority", title: Dictionary.priority },
    { name: "price", title: Dictionary.price },
    { name: "starRate", title: Dictionary.starRating },
    { name: "name", title: Dictionary.hotelName },
    { name: "gueatRate", title: Dictionary.ReviewScore }
  ];

  useEffect(() => {
    const params = {
      CityId: parameters.locationId,
      IsInstant: null,
      MaxPrice: 20000000,
      PageNumber: 1,
      PageSize: 500,
      SortColumn: 'Priority',
      SortDirection: 'Desc'
    };

    const fetchData = async () => {
      setSearchResponse();
      setLoading(true);
      resetFiltersAndPagination();
      const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${HotelURL.Availability}`,
        params,
        {
          headers: {
            ...Header,
            apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
            'Accept-Language': 'fa-IR'
          }
        }
      );
      if (response?.data) {
        setSearchResponse(response.data);
        setLoading(false);
      }

    }

    fetchData();


  }, [props.location.search]);


  useEffect(() => {

    if (hotelIds?.length) {

      const fetchAvailability = async () => {
        setAvailabilityResponse();
        const params = {
          hotelIds,
          checkIn: checkIn,
          checkOut: checkOut
        }
        const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${HotelURL.HotelAvailabilityById}`,
          params,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data) {
          setAvailabilityResponse(response.data.result);
        }
      }
      fetchAvailability();


      const fetchRates = async () => {
        setRateResponse();
        const params = {
          hotelIds
        }
        const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${HotelURL.GetRates2}`,
          params,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data) {
          setRateResponse(response.data);
        }
      }
      fetchRates();

    }

  }, [hotelIds?.length]);


  let hotels;
  let filteredHotels;

  if (serachResponse?.Hotels) {
    hotels = serachResponse.Hotels.map(item => {

      let rateInformation = 'pending';
      let priceInformation = 'pending';

      if (availabilityResponse) {
        const hotelPriceInfo = availabilityResponse.hotels?.find(hotelItem => hotelItem.id === item.HotelId);
        if (!hotelPriceInfo){
          priceInformation = "not-priced";
        }else if (hotelPriceInfo.salePrice && hotelPriceInfo.salePrice > 10000){
          priceInformation = hotelPriceInfo;
        }else{
          priceInformation = "need-to-inquire";
        }
      }

      if (rateResponse) {
        const itemRateInfo = rateResponse.find(hotelItem => hotelItem.HotelId === item.HotelId);
        if (itemRateInfo && itemRateInfo.Satisfaction >= 50 && itemRateInfo.TotalRowCount > 0) {
          rateInformation = itemRateInfo;
        } else {
          rateInformation = "not-rated";
        }
      }

      return ({
        ...item,
        priceInformation: priceInformation,
        rateInformation: rateInformation
      })
    })
  }

  const { selectedFilters } = props;

  if (hotels) {
    
    filteredHotels = hotels.filter(item => {

      let status = true;

      //check hotel name:
      if (selectedFilters.name && !item.HotelName.includes(selectedFilters.name)) {
        status = false;
      }

      //check availibility:
      if (selectedFilters.onlyAvailable && item.priceInformation === "not-priced") {
        status = false;
      }

      //check starRating:
      if (selectedFilters.starRating.length && !selectedFilters.starRating.includes(item.HotelRating)) {
        status = false;
      }

      //check price:
      if (selectedFilters.priceRange.length && (
        item.priceInformation.salePrice > selectedFilters.priceRange[1]
        ||
        item.priceInformation.salePrice < selectedFilters.priceRange[0]
      )) {
        status = false;
      }

      //check facilities:
      if (selectedFilters.facilities.length && selectedFilters.facilities.every(facilityItem => !(item.Facilities.find(x => x.Keyword === facilityItem)))) {
        status = false;
      }

      //check type:
      if (selectedFilters.accommodationType.length && !(selectedFilters.accommodationType.some(typeItem => item.HotelTypeId === typeItem))) {
        status = false;
      }

      //check guest rating:
      if (selectedFilters.reviewScore.length && !(selectedFilters.reviewScore.some(scoreRangeItem => {

        const min = +scoreRangeItem.split("-")[0];
        const max = +scoreRangeItem.split("-")[1];
        const itemSatisfaction = item.rateInformation.Satisfaction;

        return (itemSatisfaction && itemSatisfaction <= max && itemSatisfaction >= min);
      }))) {
        status = false;
      }

      return status;

    });
  }

  filteredHotels?.sort((b, a) => {
    switch (sortBy) {

      case "priority":

        if (reverseSort) {
          return (a.Priority - b.Priority);
        } else {
          return (b.Priority - a.Priority);
        }


      case "name":

        const farsiAlphabet = ["آ", "ا", "ب", "پ", "ت", "ث", "ج", "چ", "ح", "خ", "د", "ذ", "ر", "ز", "س", "ش", "ص", "ض", "ط", "ظ", "ع", "غ", "ف", "ق", "ک", "گ", "ل", "م", "ن", "و", "ه", "ی",
          "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

        const x = a.HotelName.toLowerCase().trim();
        const y = b.HotelName.toLowerCase().trim();

        for (let i = 0; i < y.length; i++) {
          if (farsiAlphabet.indexOf(y[i]) < farsiAlphabet.indexOf(x[i])) {
            return reverseSort ? 1 : -1;
          }
          if (farsiAlphabet.indexOf(y[i]) > farsiAlphabet.indexOf(x[i])) {
            return reverseSort ? -1 : 1;
          }
        }


      case "starRate":

        if (reverseSort) {
          return (b.HotelRating - a.HotelRating);
        } else {
          return (a.HotelRating - b.HotelRating);
        }


      case "price":

        if (a.priceInformation.salePrice && b.priceInformation.salePrice) {
          return reverseSort ? a.priceInformation.salePrice - b.priceInformation.salePrice : b.priceInformation.salePrice - a.priceInformation.salePrice
        } else if (b.priceInformation.salePrice) {
          return -1
        } else {
          return 1
        }


      case "gueatRate":

        if (b.rateInformation.Satisfaction && a.rateInformation.Satisfaction) {
          return reverseSort ? b.rateInformation.Satisfaction - a.rateInformation.Satisfaction : a.rateInformation.Satisfaction - b.rateInformation.Satisfaction
        } else if (b.rateInformation.Satisfaction) {
          return -1
        } else {
          return 1
        }




      default:
        return (false)
    }
  });

  filteredHotels?.sort((b, a) => {
    if (!a.priceInformation.salePrice && b.priceInformation.salePrice) {
      return -1
    } else if (a.priceInformation.salePrice && !b.priceInformation.salePrice) {
      return 1
    } else if (
        !a.priceInformation.salePrice 
        && !b.priceInformation.salePrice 
        && b.priceInformation === 'need-to-inquire'
        && a.priceInformation === 'not-priced'
        ){
      return -1
    }
  });

  const scrollTolist = () => { if(searchResultRef && searchResultRef.current ){ searchResultRef.current.scrollIntoView({behavior: "smooth"})}} ;

  useEffect(() => {
    setPage([0, 9]);
    scrollTolist();
  }, [
    sortBy,
    reverseSort,
    selectedFilters.name,
    selectedFilters.onlyAvailable,
    selectedFilters.starRating.length,
    selectedFilters.priceRange[0],
    selectedFilters.priceRange[1],
    selectedFilters.facilities.length,
    selectedFilters.accommodationType.length,
    selectedFilters.reviewScore.length

  ]);

  const hotelsMapData = filteredHotels?.filter(x => x.Latitude && x.Longitude).map(item => ({
    latitude: item.Latitude,
    longitude: item.Longitude,
    popupContent: <div className="map-custom-popup-wrapper">
      <div
        className={`hotel-image ${item.ImageUrl ? "" : "default-image"}`}
        style={{ backgroundImage: `url("${item.ImageUrl || HotelNoImage}")` }}
      />
      <div className="popup-content">
        <b className="margin-end-half">{item.HotelTypeName} {item.HotelName} {item.CityName}</b>
        <Rating number={item.HotelRating} className="inline-block-middle margin-end-half" />

        <Link
          className="button full-width red-btn has-arrow nowrap margin-top-light"
          target="_blank"
          to={`/domesticHotel/details?id=${item.HotelId}&checkin=${checkIn}&checkout=${checkOut}`}
        >
          {Dictionary.seeRooms}
        </Link>
      </div>
    </div>
  }));

  const isStyle3 = LayoutConfig.themeClassName === 'style-3';

  return (

    <div className="search-result-page domestic-hotel-search-result">
      
      {!!isStyle3 && <FetchProgress compeleted={!!rateResponse} />}

      <div className="section-vertical-padding">
        <div className="hotelResult relative">
          {loading ? (
            <>
            {isStyle3 ? (
              <div className="page-container margin-bottom-large">
                
                <Skeleton active paragraph={{ rows: 0 }} title={{width:"150px"}} className="one-line-skeleton " />
                <hr className="margin-top margin-bottom" />

                <div className="float-row padding-top">

                  <div className="col-small-12 col-medium-3 margin-bottom hidden-xsmall hidden-small">
                    <div className="box-border bg-white">
                      <div className="sidebar-map-skeleton-wrapper">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton" />
                      </div>
                      <div className="card-padding">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        <hr className="margin-top" />
                        
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton margin-bottom margin-top" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton margin-bottom" />
                        
                        <hr className="margin-top" />

                        <Skeleton active />
                        <hr className="margin-top" />
                        <Skeleton active />
                        <hr className="margin-top" />
                        <Skeleton active />
                        <hr className="margin-top" />
                        <Skeleton active />
                      </div>
                    </div>
                  </div>

                  <div className="col-small-12 col-medium-9 margin-bottom">

                    <div className="box-border margin-bottom">
                      <div className="card-padding justify-between">
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end" />
                      </div>
                      <div className="bg-white padding-h-5 justify-between">
                        {[1,2,3,4,5].map(item => (
                          <div key={item} className="padding-h-5 padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />
                          </div>
                        ))}
                      </div>
                    </div>

                    <Skeleton active paragraph={{ rows: 0 }} title={{width:"100px"}} className="one-line-skeleton margin-bottom" />

                    <div className="justify-between margin-bottom">
                        <Skeleton active paragraph={{ rows: 0 }} title={{width:"70px"}} className="one-line-skeleton" />
                        <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton text-end" />
                    </div>

                    {[1,2,3,4].map(item => (
                      <div key={item} className="box-border margin-bottom hotel-item-skeleton">
                        <div className="image" />

                        <div className="card-padding large">
                          <Skeleton active paragraph={{ rows: 3 }} className="origin" />
                        </div>

                        <div className="card-padding">
                          <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                          <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton full-width-button-skeleton" />
                        </div>
                      </div>
                    ))}



                  </div>
                </div>
              </div>
            ):(
              <Loading fullPage description={Dictionary.SearchingBestPrices + " ..."} />
            )}
            </>
          ) : !serachResponse?.Hotels ? (
            <div className="page-container">
              <div className="section-vertical-padding">
                <h2 className="page-title">
                  {Dictionary.NoHotelsFoundForYourRequest}.
                  <div className="page-subtitle">
                    {Dictionary.pleaseTryAgain}.
                  </div>
                </h2>
                <div className="clearfix alert-cart card">
                  <img
                    src={danger}
                    className="alert-icon"
                    alt="danger-icon"
                  />
                  <div className="alert-content">
                    <h6 className="alert-title">
                      {Dictionary.NoHotelsFoundForYourRequest}.
                    </h6>
                    <p className="no-margin">{Dictionary.pleaseTryAgain}.</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {LayoutConfig.themeClassName === "padide-com-theme" || (
                <div className="page-container">
                  <h2 className="page-title">{Dictionary.selectHotel}</h2>
                </div>
              )}
              <div className="page-container no-padding-mobile">
                <div className="avails-row float-row">
                  <div className="col-small-12 col-medium-3">
                    <div className="sidebar">
                      <div className="map-btn-holder">
                      {filteredHotels?.length > 0 && (
                        <button
                          className="map-view-btn"
                          type={"button"}
                          onClick={() => {setShowMap(prevState => !prevState)}}
                        >
                          {showMap? Dictionary.listView: Dictionary.viewMap}
                        </button>
                      )}
                    </div>
                      <div className={`box-border bg-white filters-holder ${showfilter ? "showfilter" : ""}`} >
                        <DomesticHotelFilter scrollTolist={scrollTolist} hotels={hotels} />
                        <button
                          type="button"
                          className="button red-btn filter-close-btn"
                          onClick={() => { setShowfilter(prevState => !prevState); }}
                        >
                          {Dictionary.close}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-small-12 col-medium-9">
                    {LayoutConfig.themeClassName === "padide-com-theme" || (
                      <div className="result-page-search-bar hidden-xsmall hidden-small">
                        <div className="search-form-wrapper">
                          <DomesticHotelSearchForm
                            collapseMode
                            fixedSearchHolder
                          />
                        </div>
                      </div>
                    )}
                    <div
                    //ref={element => {this.listHolder = element}}
                    >
                      {serachResponse?.Hotels?.length > 0 ? (
                        <div className="avails-holder" >
                          <div className="results-sorting-block">
                            <div className="clearfix">
                              <div className="pull-start-small">
                                {Dictionary.sortBy}:
                              </div>
                              <div className="pull-end-small">
                                {sortOptions.map(sortItem => <button
                                  key={sortItem.name}
                                  type="button"
                                  className={`button results-sort-btn domestic-hotel-sort-btn ${sortBy !== sortItem.name ? "disable" : reverseSort ? "" : "low"}`}
                                  onClick={() => {
                                    if (sortBy === sortItem.name) {
                                      setReverseSort(prevState => !prevState);
                                    } else {
                                      setSortBy(sortItem.name);
                                      setReverseSort(false);
                                    }
                                  }}
                                >
                                  {sortItem.title}
                                </button>)}
                              </div>

                            </div>
                          </div>
                          <div ref={searchResultRef}>
                            {showMap ? (
                              <div className="showMap">
                                {hotelsMapData?.length > 0 && (
                                  <div>
                                    <Map hotels = {hotelsMapData} />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="showResult">

                                {filteredHotels?.slice(page[0], page[1]).map(item => <HotelItem 
                                  key={item.HotelId} 
                                  hotel={item} 
                                  dateInfo={{checkIn:checkIn,checkOut:checkOut,duration:duration}}
                                />)}

                                {filteredHotels?.length > 10 && (
                                  <Page
                                    length={filteredHotels.length}
                                    itemCurrent={page[0]}
                                    itemPerPage={10}
                                    onChangeValue={(start, end) => { setPage([start, end]) }}
                                  />
                                )}

                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="section-vertical-padding text-center">
                          <div>
                            <span className="itours-icon icon-xlarge error-icon" />
                          </div>
                          <h5 className="normal-title">
                            {Dictionary.NoHotelsFoundForYourRequest}.
                            <div className="page-subtitle">
                              {Dictionary.pleaseTryAgain}.
                            </div>
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="filter-responsive-btn"
                    onClick={() => { setShowfilter(prevState => !prevState); }}
                  >
                    {Dictionary.filters}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  selectedFilters: state.DomesticHotel.selectedFilters
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => {dispatch(showError(param));},
  changeFiltersHandle: (type, value) => {dispatch(setDomesticHotelFilter(type, value))}
  
});

export default connect(mapStateToProps, mapDispatchToProps)(DomesticHotelResult);
