import React from "react";
import axios from "axios";
import { Header, Flight as FlightURL, ServerAddress } from "../../Enum/Urls";
import { connect } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import _ from "lodash";

import { resetFilters, setAvailableFilters } from "../../Actions/FlightAction";
import FilterForm from "../../components/Flight/FilterForm";
import BaggageFilter from "../../components/Flight/BaggageFilter";
import ListItem from "../../components/Common/ListItem.js";
import { Travel } from "../../Enum/Models";
import SearchForm from "../../components/Flight/SearchForm";
import AirlineFilter from "../../components/Flight/AirlineFilter";
import Loading from "../../components/Common/Loading";
import Page from "../../components/Common/Page";
import { showError } from "../../Actions/VisualDataAction";
import FlightFormSearchTOF from "../../components/TOF/Flight-Form-Search-TOF";
import LayoutConfig from "../../Config/layoutConfig";
import { calculateMinutes } from "../../helpers/helpers";
import NextAndPrev from "../../components/Flight/NextAndPrev";
import { Skeleton } from "antd";
import FetchProgress from "../../components/Common/FetchProgress.js";

class FlightResult extends React.Component {
  state = {
    flights: [],
    loading: false,
    firstItemIndex: 0,
    lastItemIndex: 9,
    showfilter: false,
    showsearch: false,
    sort: "cheapest",
    timeoutModalVisibility: false
  };

  setPageItems(first, last) {
    this.setState({ firstItemIndex: first, lastItemIndex: last });
  }

  resultsTimer = null;

  search() {

    this.props.resetAllFilters();
    const params = this.getParameters();

    this.setState({
      flights: [],
      loading: true
    });
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetFlights}`,
      data: { ...params },
      headers: { ...Header }
    }).then((response) => {
      this.setState({
        flights: response.data.result?.pricedItineraries
      });
      this.props.setAvailableFilters(response.data.result?.pricedItineraries);
      this.resultsTimer = setTimeout(() => { this.setState({ timeoutModalVisibility: true }) }, 600000);

    }).catch((error) => {
      try {
        this.props.showError({ visibility: true, content: error.response.data.error.message })
      } catch (e) {
        this.props.showError({ visibility: true, content: "Internal Error,Please refresh the page" })
      }
    }).finally(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.sort !== this.state.sort) {
      this.setState({
        firstItemIndex: 0
      });
    }

    if (this.props.userData && (this.props.userData !== prevProps.userData) && this.props.userData.isEmailConfirmed) {
      if (this.props.naminTravelSelectedFlight) {
        if (this.props.naminTravelSelectedFlight.extraBaggage) {
          this.props.history.push(
            `/flight/getpassenger?key=${this.props.naminTravelSelectedFlight.key}&extraBaggage=true`
          );
        } else {
          this.props.history.push(
            `/flight/getpassenger?key=${this.props.naminTravelSelectedFlight.key}`
          );
        }

      }
    }
    if (this.props.location.search !== prevProps.location.search) {
      this.search();
    }
    if (this.state.loading !== prevState.loading || prevState.first !== this.state.first) {
      if (process.env.REACT_APP_THEME === 'NAMINTRAVELCOM') {
        this.scrollToAirlineFilter();
      } else {
        this.scrollToResults();
      }
    }

    if (this.props.location !== prevProps.location) {
      clearTimeout(this.resultsTimer);
    }
    const { selectedFilters } = this.props;
    const prevSelectedFilters = prevProps.selectedFilters;
    if (
      selectedFilters.stop?.length !== prevSelectedFilters.stop?.length
      ||
      selectedFilters.cabinClass?.length !== prevSelectedFilters.cabinClass?.length
      ||
      selectedFilters.airline?.length !== prevSelectedFilters.airline?.length
      ||
      selectedFilters.departureTime?.length !== prevSelectedFilters.departureTime?.length
      ||
      selectedFilters.arrivalTime?.length !== prevSelectedFilters.arrivalTime?.length
      ||
      selectedFilters.airlinePrice?.length !== prevSelectedFilters.airlinePrice?.length
      ||
      selectedFilters.flightType?.length !== prevSelectedFilters.flightType?.length
      ||
      selectedFilters.baggage?.length !== prevSelectedFilters.baggage?.length
      ||
      selectedFilters.baggageDetail?.length !== prevSelectedFilters.baggageDetail?.length
      ||
      selectedFilters.stopTime?.length !== prevSelectedFilters.stopTime?.length
      ||
      selectedFilters.duration?.length !== prevSelectedFilters.duration?.length
    ) {
      this.setPageItems(0, 9);
    }

  }
  componentWillUnmount() {
    clearTimeout(this.resultsTimer);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.search();
  }

  handleSort = (type) => {
    this.setState({
      sort: type
    });
  };

  getParameters() {
    const parameters = queryString.parse(this.props.location.search);
    parameters.adult = +parameters.adult;
    parameters.child = +parameters.child;
    parameters.infant = +parameters.infant;
    parameters.departureDateTimes = parameters.departureDateTimes.split("|");
    parameters.originCodes = parameters.originCodes.split("|");
    parameters.originNames = parameters.originNames.split("|");
    parameters.destinationCodes = parameters.destinationCodes.split("|");
    parameters.destinationNames = parameters.destinationNames.split("|");
    parameters.isEthnic = process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' ? true : undefined;
    //parameters.supplier = (process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM')?"Ethnic":undefined;    
    if (parameters.airTripType === "RoundTrip") {
      parameters.originCodes.push(parameters.destinationCodes[0]);
      parameters.destinationCodes.push(parameters.originCodes[0]);
    }

    return parameters;
  }

  scrollToResults = () => { if (this.availsHolder) {
    setTimeout(()=>{
      this.availsHolder.scrollIntoView({ behavior: "smooth" });   
    },1000)
  }};
  scrollToAirlineFilter = () => { if (this.airlineFilterHolder) { this.airlineFilterHolder.scrollIntoView({ behavior: "smooth" }) } };


  render() {
    const { Dictionary } = this.props;
    const { flights, sort } = this.state;

    let filteredFlights;

    if (flights.length) {

      const filterFunction = item => {

        const { selectedFilters } = this.props;

        //filter stop:
        let itemStop;
        if (item.airItinerary.originDestinationOptions.some(option => option.numberOfStop > 1)) {
          itemStop = 2;
        } else if (item.airItinerary.originDestinationOptions.every(option => option.numberOfStop === 0)) {
          itemStop = 0;
        } else {
          itemStop = 1;
        }
        if (selectedFilters.stop.length && !selectedFilters.stop.includes(itemStop)) {
          return false
        }

        //filter airline:
        const itemAirline = item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.name;
        if (selectedFilters.airline.length && !selectedFilters.airline.includes(itemAirline)) {
          return false
        }

        //filter cabinClass:
        const itemCabinClass = item.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass.name;
        if (selectedFilters.cabinClass.length && !selectedFilters.cabinClass.includes(itemCabinClass)) {
          return false
        }


        const getMinutes = time => {
          const timeArray = time.split("T")[1]?.split(":");
          return (+timeArray[0] * 60 + +timeArray[1]);
        }
        //filter departureTime:
        if (selectedFilters.departureTime.length) {

          const itemDepartureDateTime = item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime;
          const itemDepartureMinutes = getMinutes(itemDepartureDateTime);

          if (selectedFilters.departureTime.every(filterItem => {
            const min = +filterItem.split("-")[0];
            const max = +filterItem.split("-")[1];
            if (itemDepartureMinutes > max || itemDepartureMinutes < min) {
              return true
            } else {
              return false
            }
          })) {
            return false
          }
        }

        //filter arrivalTime:
        if (selectedFilters.arrivalTime.length) {
          const itemLastRoute = item.airItinerary.originDestinationOptions[item.airItinerary.originDestinationOptions.length - 1];
          const itemLastSegment = itemLastRoute.flightSegments[itemLastRoute.flightSegments.length - 1];
          const itemArrivalDateTime = itemLastSegment.arrivalDateTime;
          const itemArrivalMinutes = getMinutes(itemArrivalDateTime);

          if (selectedFilters.arrivalTime.every(filterItem => {
            const min = +filterItem.split("-")[0];
            const max = +filterItem.split("-")[1];
            if (itemArrivalMinutes > max || itemArrivalMinutes < min) {
              return true
            } else {
              return false
            }
          })) {
            return false
          }
        }


        //filter airlinePrice:
        if (selectedFilters.airlinePrice.length && selectedFilters.airlinePrice.every(filterItem => {
          const filterItemAirline = filterItem.split("_")[0];
          const filterItemHasStop = filterItem.split("_")[1] === "withStop" ? true : false;
          const filterItemEthnic = filterItem.split("_")[2];

          const flightItemAirline = item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.code;
          const flightItemHasStop = item.airItinerary.originDestinationOptions.some(itineraryItem => itineraryItem.numberOfStop > 0);
          const flightItemIsEthnic = item.isEthnic;

          if (flightItemAirline !== filterItemAirline || flightItemHasStop !== filterItemHasStop) {
            return true;
          }
          if ((filterItemEthnic === "ethnic" && !flightItemIsEthnic) || (filterItemEthnic === "noEthnic" && flightItemIsEthnic)) {
            return true
          }
        })) {
          return false;
        }

        const allSegments = item.airItinerary.originDestinationOptions.reduce((segments, originDestinationItem) => ([...segments, ...originDestinationItem.flightSegments]), []);

        //filter baggage:        
        if (selectedFilters.baggage?.length === 1) {

          const getSegmentBaggage = segment => segment.baggageInformation.find(baggageInfoItem => baggageInfoItem.passengerType === "ADT");
          const itemHasBaggage = allSegments.every(segmentItem => getSegmentBaggage(segmentItem).baggageAllowance > 0);

          if ((selectedFilters.baggage.includes("withBaggage") && !itemHasBaggage) || (selectedFilters.baggage.includes("withoutBaggage") && itemHasBaggage)) {
            return false;
          }
        }

        //filter baggage detail:
        if (selectedFilters.baggageDetail?.length > 0) {

          const segmentAdultBaggages = allSegments.map(segment => segment.baggageInformation.find(baggageItem => baggageItem.passengerType === "ADT")).map(baggageObject => baggageObject.baggageAllowance ? `${baggageObject.baggageAllowance}_${baggageObject.unitType}` : 0);
          if (segmentAdultBaggages.length && !selectedFilters.baggageDetail.some(option => segmentAdultBaggages.includes(option))) {
            return false
          }
        }

        //filter duration:
        if (selectedFilters.duration?.length > 1) {
          const itemDuration = item.airItinerary.originDestinationOptions.reduce(
            (totalDuration, currentItem) => totalDuration + calculateMinutes(currentItem.journeyDuration),
            0
          ) / 60;
          if (itemDuration > selectedFilters.duration[1] || itemDuration < selectedFilters.duration[0]) {
            return false;
          }
        }

        //filter stop time:
        if (selectedFilters.stopTime?.length > 1) {
          const ItemstopTime = item.airItinerary.originDestinationOptions.flatMap(originDestinationItem => originDestinationItem.flightSegments).reduce(
            (totalDuration, currentItem) => totalDuration + calculateMinutes(currentItem.stopTime),
            0
          ) / 60;
          if (ItemstopTime > selectedFilters.stopTime[1] || ItemstopTime < selectedFilters.stopTime[0]) {
            return false;
          }
        }

        return true
      }

      const calcDurationMinutes = duration => {

        let days, hours, minutes;

        if (duration.includes('.')) {
          days = +duration.split(".")[0];
          hours = +duration.split(".")[1].split(":")[0];
          minutes = +duration.split(".")[1].split(":")[1];
        } else {
          days = 0;
          hours = +duration.split(":")[0];
          minutes = +duration.split(":")[1];
        }

        return (minutes + 60 * hours + 24 * 60 * days);

      }


      const getOriginDestinationOptionsMinutes = options => {

        if (options.some(option => option.journeyDuration === "00:00:00")) {
          return 0;
        }

        const minutes = options.reduce((sum, current) => {
          const currentMinutes = calcDurationMinutes(current.journeyDuration);
          return sum + currentMinutes
        }, 0);

        return minutes;
      }

      const sortFunction = (b, a) => {

        if (!sort) return 1;

        const bFare = b.airItineraryPricingInfo.itinTotalFare.totalFare;
        const aFare = a.airItineraryPricingInfo.itinTotalFare.totalFare;

        if (sort === "theMostExpensive") {
          return (aFare - bFare);
        }

        if (sort === "cheapest") {
          return (bFare - aFare);
        }

        if (sort === "fastest") {

          const aDuration = getOriginDestinationOptionsMinutes(a.airItinerary.originDestinationOptions);
          const bDuration = getOriginDestinationOptionsMinutes(b.airItinerary.originDestinationOptions);

          if (aDuration === 0) {
            return -1
          }

          return (aDuration !== 0 && bDuration !== 0 && bDuration - aDuration);
        }

        return 1;
      }

      filteredFlights = flights.filter(filterFunction).sort(sortFunction);

    }

    let loadingDescription = Dictionary.SearchingBestPrices + " ...";

    if (process.env.REACT_APP_THEME === 'NAMINTRAVELCOM') {
      loadingDescription = "We look at the price, travel time, number of stops, and baggage allowance to determine which options you might like best."
    }
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';
    return (
      <div className="flight search-result-page">

        {!!isStyle3 && <FetchProgress compeleted={!!this.state.flights?.length} />}

        {(this.state.loading)?(
          <>
            {isStyle3 ? (
              <div className="page-container margin-bottom-large">
                <div className="float-row padding-top">

                  <div className="col-small-12 col-medium-3 margin-bottom hidden-xsmall hidden-small">
                    <div className="box-border bg-white card-padding">
                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                      <hr className="margin-top" />
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                    </div>
                  </div>

                  <div className="col-small-12 col-medium-9 margin-bottom">

                    <div className="route-skeleton margin-bottom">
                      <Skeleton active paragraph={{ rows: 1 }} className="origin" />
                      <Skeleton active paragraph={{ rows: 1 }} className="origin text-end" />
                      <Skeleton active paragraph={{ rows: 1 }} className="origin hidden-xsmall" />
                      <Skeleton active paragraph={{ rows: 1 }} className="origin hidden-xsmall" />
                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton hidden-xsmall" />
                    </div>
                    <hr className="margin-top margin-bottom" />
                    <div className="flight-skeleton-footer margin-bottom">
                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton" />
                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end button-skeleton" />
                    </div>


                    {[1, 2, 3, 4, 5].map(item => (
                      <div
                        key={item}
                        className="box-border bg-white card-padding margin-bottom flight-item-skeleton"
                      >
                        <div className="route-skeleton">
                          <span className="airline" />
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton hidden-xsmall hidden-small" />
                          <Skeleton active paragraph={{ rows: 2 }} className="origin" />
                          <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} className="one-line-skeleton hidden-xsmall" />
                          <Skeleton active paragraph={{ rows: 2 }} className="origin text-end" />
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-center hidden-xsmall" />
                        </div>
                        <hr className="margin-top margin-bottom" />
                        <div className="flight-skeleton-footer">
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton button-skeleton" />
                          <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-end button-skeleton" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <Loading fullPage description={loadingDescription} />
            )}
          </>
        ):(
          <>
        {this.state.timeoutModalVisibility &&
          <div className="itours-modal visible">
            <div className="modal-overlay" onClick={this.closeItinerariModal} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <div className="text-center margin-top margin-bottom">
                  <div className="margin-bottom">
                    <span className="itours-icon gray-clock-icon icon-xlarge" />
                  </div>
                  <h4 className="normal-title margin-bottom">
                    {Dictionary.flightStillAroundTitle}
                  </h4>
                  <p className="timer-modal-text">
                    {Dictionary.flightStillAroundP1} {Dictionary.flightStillAroundP2}
                  </p>
                  <Link to="/" className="button grey-btn margin-end">
                    {Dictionary.home}
                  </Link>
                  <button
                    type="button"
                    className="button color-1-btn"
                    onClick={() => window.location.reload()}
                  >
                    {Dictionary.searchAgain}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "naminTravel-theme__") && (
          <div className="result-inline-search-section">
            <div className="page-container">
              <FlightFormSearchTOF inline={true} onSearchRepeate={this.search} />
            </div>
          </div>
        )}

        {this.props.airlineFilter.length ? (
          <div className="airline-filter-holder" ref={element => { this.airlineFilterHolder = element }}>
            <AirlineFilter />
          </div>
        ) : null}

        <div className="page-container" ref={element => { this.availsHolder = element }}>
          {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') && <div className="float-row padding-top-half">
            <div className="col-small-12 col-medium-3">
              {/* <div className="okr-bg side-top-text margin-bottom hidden-responsive">
                We look at the price, travel time, number of stops, and baggage allowance to determine which options you might like best. 
              </div> */}
              <BaggageFilter />
            </div>
            <div className="col-small-12 col-medium-9">
              <div className="search-form-wrapper ">
                <SearchForm
                  inline={true}
                  onSearchRepeate={this.search}
                  originCity={flights[0]?.airItinerary.originDestinationOptions[0].flightSegments[0].departureAirport.cityName || ""}
                  destinationCity={flights[0] ? _.last(flights[0].airItinerary.originDestinationOptions[0].flightSegments).arrivalAirport.cityName : ""}
                />
              </div>
            </div>
          </div>}

          <div className="avails-row float-row flight-avails-row">
            <div className="col-small-12 col-medium-3">
              <div className="sidebar medium-box-border bg-white">
                {/* <div className="sidebar-item clearfix">
                    <span className="results-number-holder">
                        <b> {<span>{this.state.result && this.state.result.length}</span>} </b> flyreise funnet
                    </span>
                    <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                        Filtre
                    </button>
                </div> */}
                <div className={`filters-holder ${this.state.showfilter ? "showfilter" : ""}`} >
                  <FilterForm />
                  <button
                    type="button"
                    className="button red-btn filter-close-btn"
                    onClick={() => {
                      this.setState({
                        showfilter: false,
                      });
                    }}
                  >
                    {Dictionary.close}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-small-12 col-medium-9">
              {LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme' || (
                <div className="search-form-wrapper ">
                  <SearchForm
                    inline={true}
                    onSearchRepeate={this.search}
                    originCity={flights[0]?.airItinerary.originDestinationOptions[0].flightSegments[0].departureAirport.cityName || ""}
                    destinationCity={flights[0] ? _.last(flights[0].airItinerary.originDestinationOptions[0].flightSegments).arrivalAirport.cityName : ""}
                  />
                </div>
              )}

              <div>
                {filteredFlights?.length ? (
                  <div className="avails-holder">
                    <div className="sort-next-prev margin-bottom-half">
                      <NextAndPrev />
                      <div className="sort-container">
                        <label className="margin-end-light" htmlFor="sort">
                          {Dictionary.sortBy}:{" "}
                        </label>

                        {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? (
                          <div className="namin-sort-btn-group">
                            <button
                              type="button"
                              onClick={() => this.handleSort("cheapest")}
                              className={`btn ${this.state.sort === "cheapest" ? "active" : ""}`}
                            >
                              {Dictionary.cheapest}
                            </button>
                            <button
                              type="button"
                              onClick={() => this.handleSort("fastest")}
                              className={`btn ${this.state.sort === "fastest" ? "active" : ""}`}
                            >
                              {Dictionary.fastest}
                            </button>
                          </div>
                        ) : (
                          <select onChange={e => this.handleSort(e.target.value)} className="results-sort-select">
                            <option value="cheapest">{Dictionary.cheapest}</option>
                            <option value="theMostExpensive">{Dictionary.theMostExpensive}</option>
                            <option value="fastest">{Dictionary.fastest}</option>
                          </select>
                        )}
                      </div>
                    </div>

                    {/* {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') && <a href="https://itours.no" className="card-padding box-border itours-no-card-link">
                          {Dictionary.ToSeeNormalPricesClickHere}
                    </a>} */}

                    <ListItem
                      data={filteredFlights}
                      last={this.state.lastItemIndex}
                      start={this.state.firstItemIndex}
                      type={Travel.flight}
                    />
                    {filteredFlights.length > 10 && (
                      <Page
                        length={filteredFlights.length}
                        itemCurrent={this.state.firstItemIndex}
                        itemPerPage={10}
                        onChangeValue={this.setPageItems.bind(this)}
                      />
                    )}
                    {filteredFlights.length > 0 && (
                      <button
                        type="button"
                        className="filter-responsive-btn"
                        onClick={() => {
                          this.setState((prevState) => ({
                            showfilter: !prevState.showfilter,
                          }));
                        }}
                      >
                        {Dictionary.filters}
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="section-vertical-padding text-center">
                    <div>
                      <span className="itours-icon icon-xlarge error-icon" />
                    </div>
                    <h5 className="normal-title">
                      {Dictionary.noFlightFoundForYourRequest}.
                      <div className="page-subtitle">
                        {Dictionary.pleaseTryAgain}.
                      </div>
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  airlineFilter: state.FlightSearch.filterShow.airlinePrice,
  selectedFilters: state.FlightSearch.filterSelect,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  naminTravelSelectedFlight: state.FlightSearch.naminTravelSelectedFlight,
  userData: state.UserData.userData
});

const mapDispatchToProps = (dispatch) => ({
  showError: params => {
    dispatch(showError(params));
  },
  setAvailableFilters: flights => {
    dispatch(setAvailableFilters(flights))
  },
  resetAllFilters: () => {
    dispatch(resetFilters());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlightResult));
