import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LayoutConfig from '../../Config/layoutConfig';
import { setLang } from '../../Actions/VisualDataAction';

import ir from '../../image/flags/ir.png';
import ar from '../../image/flags/ar.png';
import iq from '../../image/flags/iq.png';
import dk from '../../image/flags/dk.png';
import tr from '../../image/flags/tr.png';
import us from '../../image/flags/us.png';
import fi from '../../image/flags/fi.png';
import ku from '../../image/flags/ku.png';
import se from '../../image/flags/se.png';
import no from '../../image/flags/no.png';

const Languages = props => {

    const dispatch = useDispatch();

    const Dictionary = useSelector(state => state.VisualData.Dictionary);

    const [open, setOpen] = useState(false);

    const LanguageWrapper = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const { currentLanguage } = props;

    const flags = {
        IR: ir,
        AR: process.env.REACT_APP_THEME ==='AYANAIRCOM' ? iq : ar,
        DK: dk,
        TR: tr,
        US: us,
        FI: fi,
        KU: ku,
        SE: se,
        NO: no
    }

    if (!LayoutConfig.languages || LayoutConfig.languages < 2) {
        return null;
    }

    const setLanguage = lang => {
        if (lang !== currentLanguage) {
            localStorage.setItem('selectedLanguage', lang);
            dispatch(setLang(lang));
            setOpen(false);
            //window.location.reload();
        }
    }

    const handleClickOutside = (e) => {
        if (LanguageWrapper.current && !LanguageWrapper.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const createTitle = language => {
        switch (language){
            case "US":
                return "English";
            case "AR":
                return "العربية";
            case "KU":
                return "کوردی";
            default:
                return language;
        }
    }

    return (
        <div className={`relative ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"margin-start-half":"margin-end-half"} languages-wrapper`}>

            <button type='button' onClick={() => { setOpen(prevState => !prevState) }} className={`language-btn ${process.env.REACT_APP_THEME ==='AYANAIRCOM'?"ayan-outline-btn":""}`}>
                <img src={flags[currentLanguage]} alt={currentLanguage} width={40} className="flag pointer" /> {process.env.REACT_APP_THEME === 'AYANAIRCOM' ? createTitle(currentLanguage):""}
            </button>

            <div className={`languages-popup ${open ? "open" : ""}`} ref={LanguageWrapper}>
                {LayoutConfig.languages?.map(item => (
                    <div
                        key={item}
                        className={`language-item ${item === currentLanguage ? "active" : ""} ${(item==="KU" || item==="AR" || item==="IR")?"fa-font":"en-font"}`}
                        onClick={() => { setLanguage(item) }}
                    >
                        <img
                            src={flags[item]}
                            alt={item}
                            width={40}
                            className='flag margin-end-half'
                        />
                        {createTitle(item)}
                        {item === currentLanguage && <span className='itours-icon icon-small green-checkmark margin-start-light' />}
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Languages;