import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker";
import { connect } from "react-redux";

import { ServerAddress, DomesticHotelApiUrls, Header } from "../../Enum/Urls";
import LayoutConfig from "../../Config/layoutConfig.js";
import SearchBoxTab from '../Common/SearchBoxTab';
import Autocomplete from "../Common/Autocomplete.js";
import { createDatePickerConfig } from "../../helpers/helpers";

class DomesticHotelSearchForm extends React.Component {
  state = {
    isMobile:false,
    searchValidator: {
      location: true,
      dates: true,
    },
    validation: false,
    searchParam: {
      locationId: "",
      location: "",
      searchType: "",
      checkin: moment().format("YYYY-MM-DD"),
      checkout: moment().add(1, "day").format("YYYY-MM-DD"),
    },
    night: 1,
    searchMode: false,
    calendarIsGregorian: false,
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };

  fillFromURL() {
    if (this.props.location.search !== "") {
      this.setState({ calendarIsGregorian: true });
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.location.pathname === "/domesticHotel/details") {
        const fetchLocation = async () => {
          const locationId = parameters.id;
          const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetEntityNameByLocation}?location=${locationId}`,
            {
              headers: {
                ...Header,
                apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                'Accept-Language': 'fa-IR'
              }
            }
          );
          if (response?.data) {
            const typeOfEntity = response.data.EntityTypeId === 4 ? "Hotel":"";
            this.setState(
              {
                searchParam: {
                  ...parameters,
                  checkin: parameters.checkin?.split("T")[0] || moment().format("YYYY-MM-DD"),
                  checkout: parameters.checkout?.split("T")[0] || moment().add(1, "day").format("YYYY-MM-DD"),
                  locationId: locationId,
                  location: response.data.EntityName,
                  searchType: typeOfEntity
                },
              },
              () => {
                this.calculateDuration();
              }
            );
            
          }
        }

        fetchLocation();

      } else {
        this.setState(
          {
            searchParam: {
              ...parameters,
              checkin: parameters.checkin?.split("T")[0] || moment().format("YYYY-MM-DD"),
              checkout: parameters.checkout?.split("T")[0] || moment().add(1, "day").format("YYYY-MM-DD"),
              locationId: parameters.locationId,
              location: parameters.location
            },
          },
          () => {
            this.calculateDuration();
          }
        );
      }
    }
  }
  componentDidMount() {
    if (window.innerWidth <= 767){
      this.setState({isMobile:true});
    }
    this.fillFromURL();

    this.setState({
      searchMode:this.props.collapseMode?false:true
    });

  }
  changeCalendarType = () => {
    this.setState((prevState) => {
      return { calendarIsGregorian: !prevState.calendarIsGregorian };
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.fillFromURL();
    }
  }
  disabledDate = (index, current) => {
    // Can not select days before today and today

    if (index === "checkout") {
      return current && current <= moment(this.state.searchParam.checkin);
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  resultRef = () => {
    console.log(this.myRef);
  };
  defaultDay = new Date();
  toggleSearchMode = () => {
    this.setState((prevState) => ({
      searchMode: !prevState.searchMode,
    }));
  };
  handleClick = () => {
    this.setState({
      validation: true,
    });
    if (this.state.searchParam.locationId && this.state.searchParam.checkin && this.state.searchParam.checkout) {
      if (this.state.searchParam.searchType === 'Hotel') {
        let hotelDetailLink = `/domesticHotel/details?id=${this.state.searchParam.locationId}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}`;        
        const parameters = queryString.parse(this.props.location.search)
        if (parameters.safarmarketId){
          const sm_medium_string = parameters.utm_medium.map(item => `utm_medium=${item}`).join("&");
          hotelDetailLink += `&safarmarketId=${parameters.safarmarketId}&utm_source=${parameters.utm_source}&${sm_medium_string}`;
        }
        const currentUrl = this.props.location.pathname + this.props.location.search;
        if (currentUrl !== hotelDetailLink){
          this.props.history.push(hotelDetailLink);
        }else{
          console.log("change parameters to search new condition!")
        }

      } else {
        this.props.history.push(
          `/domesticHotel/result?locationId=${this.state.searchParam.locationId}&location=${this.state.searchParam.location}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}`
        );
      }
    }
  };

  calculateDuration = () => {
    let night = moment(this.state.searchParam.checkout).diff(
      moment(this.state.searchParam.checkin),
      "days"
    );
    this.setState({
      night: night,
    });
  };

  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }else{
      debugger;
    }
  }

  getCallback = (id, text, type) => {
    let searchParamCatch = this.state.searchParam;
    searchParamCatch.locationId = id;
    searchParamCatch.location = text;
    searchParamCatch.searchType = type;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };

  checkinChangeHandle = (d) => {
    const e = d.toDate();
    let checkoutDate = this.state.searchParam.checkout;
    if(checkoutDate &&  moment(checkoutDate).isAfter(e)){
      this.setState(prevState=>({
        searchParam: {
          ...prevState.searchParam,
          checkin: moment(e).format('YYYY-MM-DD')
        },
      }),
      () => {
        this.calculateDuration();
        this.checkinRef.closeCalendar();
      }
    );
    }else{
      this.setState(prevState=>({
          searchParam: {
            ...prevState.searchParam,
            checkin: moment(e).format('YYYY-MM-DD'),
            checkout: moment(e).add(1, 'day').format('YYYY-MM-DD'),
          },
        }),
        () => {
          this.calculateDuration();
          this.checkinRef.closeCalendar();
          this.checkoutRef.openCalendar();
        }
      );
    }
  }

  render() {
    const { Dictionary, LAN } = this.props;

    const datepickerConfig = createDatePickerConfig(LAN, this.state.datepickerLocale, this.state.isMobile);

    const serachFormOverlay = <div className="fixed-search-overlay" onClick={this.toggleSearchMode} />;
    
    const searchForm = <div className={this.props.fixedSearchHolder ? 'fixed-search-holder' : null}>
      {LayoutConfig.themeClassName === 'style-2' && !this.props.noTabs && <SearchBoxTab searchType="domesticHotel" />}
      <div className="searchbox-inner">

      {this.props.isHome && LayoutConfig.themeClassName === 'style-3' && (
        <div className="margin-bottom-half">
            <Link to="/domesticHotel" className="style3-type-radio margin-end active"> <span className="radio-mark"/> {Dictionary.domestic} </Link>
            <Link to="/hotel" className="style3-type-radio" > <span className="radio-mark"/> {Dictionary.foreign} </Link>
        </div>
      )}


        <div className="searchBar hotel">
          {this.props.collapseMode && (
            <>
              {this.state.searchMode && (
                <div className="clearfix">
                  <button type="button" className={'button show-close-btn pull-end'} onClick={this.toggleSearchMode}>
                    <span className="itours-icon gray-close-icon icon-large" />
                  </button>
                </div>
              )}
            </>
          )}
          <div className="hotel-search-form-holder">
            <div className="hotel-search-form-main">
              <div className={`float-row ${LayoutConfig.themeClassName==="style-3"?"":"half-gap"}`}>
                {!this.props.onlyDates && <div className="col-small-12 col-medium-5">
                  <div className="form-group hotel-destination-holder relative">
                    <Autocomplete
                      checkInputLang = {true}
                      defaultText={this.state.searchParam.location}
                      defaultValue={this.state.searchParam.locationId}
                      validate={this.state.searchValidator.location}
                      getValue={this.getCallback.bind(this)}
                      placeholder={Dictionary.enterYourDestination}
                      url={ServerAddress.Type + ServerAddress.DomesticHotelC + DomesticHotelApiUrls.GetLocation}
                      requestTypePost
                      min={2}
                      name="sample"
                      param="input"
                      header={{...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                      }}
                      type = "domesticHotel"
                    />
                    {this.state.validation && !this.state.searchParam.location && (
                      <b className="validator-label">{Dictionary.pleaseEnterDestination}</b>
                    )}
                  </div>
                </div>}
                <div className={`col-small-12 ${this.props.onlyDates ? "" : "col-medium-7"}`}>
                  <div className="hotel-dates-group">
                    <div className="checkin-column">
                      <div className="relative">
                        <MultiDatePicker
                          fixMainPosition={process.env.REACT_APP_THEME ==='ITOURS_IR'}
                          ref={element => {this.checkinRef = element}}
                          inputMode="none"
                          calendarPosition={datepickerConfig.calendarPosition}
                          weekDays={datepickerConfig.weekDays}
                          digits={datepickerConfig.digits}
                          inputClass="multi-datepicker-input"
                          className={datepickerConfig.className}
                          minDate={this.state.datepickerType === 'persian' ? moment().format('jYYYY/jMM/jDD') : moment().format('YYYY/MM/DD')}
                          format={'YYYY-MM-DD'}
                          calendar={this.state.datepickerType}
                          locale={this.state.datepickerLocale}
                          onFocusedDateChange={this.checkinChangeHandle}
                          value={
                            this.state.datepickerType === 'persian'
                              ? moment(this.state.searchParam.checkin, 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
                              : this.state.searchParam.checkin
                          }>
                          {this.state.isMobile && (
                            <div className="date-picker-title">{Dictionary.checkinDateSelection}</div>
                          )}                                        
                          {LAN === 'IR' && (
                            <button
                              type="button"
                              className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                              onClick={this.toggleDatepicker}>
                              {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                            </button>
                          )}
                          {this.state.isMobile && (
                            <button className={'close-datepicker-btn'} onClick={() => this.checkinRef.closeCalendar()}>
                              <span className={`itours-icon gray-close-icon icon-large`} />
                            </button>
                          )}
                        </MultiDatePicker>

                        {this.state.validation && !this.state.searchParam.checkin && (
                          <b className="validator-label">{Dictionary.pleaseSelectCheckinDate}</b>
                        )}
                      </div>
                    </div>
                    <div className="checkout-column">
                      <div className="relative">
                        <MultiDatePicker
                          fixMainPosition={process.env.REACT_APP_THEME ==='ITOURS_IR'}
                          ref={element => {this.checkoutRef = element}}
                          onFocusedDateChange={(d) => {
                            const e = d.toDate();
                            this.setState(
                              {
                                searchParam: {
                                  ...this.state.searchParam,
                                  checkout: moment(e).format('YYYY-MM-DD'),
                                },
                              },
                              () => {
                                this.calculateDuration();
                                this.checkoutRef.closeCalendar();
                              }
                            );
                          }}
                          inputMode="none"
                          calendarPosition={datepickerConfig.calendarPosition}
                          weekDays={datepickerConfig.weekDays}
                          digits={datepickerConfig.digits}
                          inputClass="multi-datepicker-input"
                          className={datepickerConfig.className}
                          minDate={
                            this.state.datepickerType === 'persian'
                              ? moment(this.state.searchParam.checkin).add(1, 'day').format('jYYYY/jMM/jDD')
                              : moment(this.state.searchParam.checkin).add(1, 'day').format('YYYY/MM/DD')
                          }
                          format={'YYYY-MM-DD'}
                          calendar={this.state.datepickerType}
                          locale={this.state.datepickerLocale}
                          value={
                            this.state.datepickerType === 'persian'
                              ? moment(this.state.searchParam.checkout, 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
                              : this.state.searchParam.checkout
                          }>
                          {this.state.isMobile && (
                            <div className="date-picker-title">{Dictionary.checkoutDateSelection}</div>
                          )}                                         
                          {LAN === 'IR' && (
                            <button
                              type="button"
                              className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                              onClick={this.toggleDatepicker}>
                              {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                            </button>
                          )}
                          {this.state.isMobile && (
                            <button className={'close-datepicker-btn'} onClick={() => this.checkoutRef.closeCalendar()}>
                              <span className={`itours-icon gray-close-icon icon-large`} />
                            </button>
                          )}
                        </MultiDatePicker>

                        {this.state.validation && !this.state.searchParam.checkout && (
                          <b className="validator-label">{Dictionary.pleaseSelectCheckoutDate}</b>
                        )}
                      </div>
                    </div>
                    <div className="nights-column">
                      <div className="relative">
                        <div className="relative">
                          <span className="form-input nights-fake-input">{this.state.night} {Dictionary.nights}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-holder">
              <button
                className={`searchBtn hotel-search-btn button ${LayoutConfig.themeClassName === 'style-3'?"yellow-btn":"red-btn"} full-width`}
                type="button"
                onClick={() => {
                  this.handleClick();
                }}>
                <span className={`itours-icon search-icon margin-end-light ${LayoutConfig.themeClassName === "style-3"?"mirror-rtl icon-small":""}`} />
                {Dictionary.search}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;

    const searchedInfo = <>
      <div className={'headerInformation'}>
        <div className={'title'}>
          <div className={'hidden-xsmall hidden-small'}>
            <span className="itours-icon hotel-dark margin-end-light icon-small" /> {Dictionary.searchSummary}
          </div>

        </div>
        <div className={'buttonContainer'}>
          <button type="button" className={`button ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"namin-blue-btn":"color-1-btn"} pull-end`} onClick={this.toggleSearchMode}>
            {Dictionary.modifySearch}
          </button>
        </div>
      </div>
      <div className="searched-information">
        <div className={'fakeForm'}>
          <div className="textbox">
            <span className="itours-icon location-dark margin-end-light icon-small" /> {this.state.searchParam.location}
          </div>
          <div className="textbox">
            <span className="itours-icon checkin-dark margin-end-light icon-small" />
            <span className="inline-block margin-end-light"> {this.state.searchParam.checkin} </span>
            <span className={'font-11 inline-block'}>
              {' '}
              {LAN === 'IR' && <>({moment(this.state.searchParam.checkin).format('jYYYY-jMM-jDD')})</>}
            </span>
          </div>
          <div className="textbox">
            <span className="itours-icon checkout-dark margin-end-light icon-small" />
            <span className="inline-block margin-end-light">{this.state.searchParam.checkout}</span>
            <span className={'font-11 inline-block'}>
              {LAN === 'IR' && <>({moment(this.state.searchParam.checkout).format('jYYYY-jMM-jDD')})</>}
            </span>
          </div>
          <div className="textbox">
            <span className="itours-icon moon-dark margin-end-light icon-small" />{' '}
            {moment(this.state.searchParam.checkout).diff(moment(this.state.searchParam.checkin), 'days')}
          </div>
        </div>
      </div>
    </>
      
    return (
      <div className={`search-box-holder clearfix ${this.props.collapseMode? 'inline': ''}`}>

        {this.state.searchMode && this.props.fixedSearchHolder && serachFormOverlay}
        
        {this.state.searchMode && searchForm}

        {!this.state.searchParam.checkin || (this.state.searchMode && !this.props.fixedSearchHolder) || searchedInfo}

      </div>
    );
  }
}
const RouterDOM = withRouter(DomesticHotelSearchForm);
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(RouterDOM);
