import { useEffect, useState } from "react";
import queryString from 'query-string';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment-jalaali";
import { Form, Input, Radio, Checkbox } from 'antd';

import { ServerAddress, Header, DomesticHotelApiUrls } from "../../Enum/Urls";
import { DomesticHotelPreReserve } from "../../Actions/DomesticHotelAction";
import { splitCodeAndNumber } from "../../Actions/UserAction";
import { showError, changeSafarmarketLayout } from "../../Actions/VisualDataAction";
import Loading from "../Common/Loading";
import LayoutConfig from "../../Config/layoutConfig";
import { CountryPostalCode } from "../../Enum/Models";
import { onChangeValidation } from "../Common/Validation";
import DomesticHotelCheckoutRoomItem from "./DomesticHotelCheckoutRoomItem";
import Rating from "../Common/Rating";
import Price from "../Common/Price";
import StickySides from "../Common/StickySides";

const DomesticHotelGetPassenger = props => {

  const { userData, Dictionary, LAN } = props;

  const { getFieldDecorator } = props.form;

  const preReserveKey = queryString.parse(props.location.search)?.preReserveKey;

  const [validationData, setValidationData] = useState();
  const [hotelData, setHotelData] = useState();
  const [loading, setLoading] = useState(true);
  const [userIsPassenger, setUserIsPassenger] = useState(true);

  const [roomChildAndExtraBed, setRoomChildAndExtraBed] = useState([]);

  const disableUserIsPassenger = () => {
    if (userIsPassenger) {
      setUserIsPassenger(false)
    }
  };

  useEffect(() => {
    props.setSafarmarketLayout(true);

    return (() => {
      props.setSafarmarketLayout(false);
    });
  }, [])

  useEffect(() => {
    if (preReserveKey) {
      window.scrollTo(0, 0);
      const fetchPrereserveData = async () => {
        const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetValidate}?Id=${preReserveKey}`,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data?.result) {
          setValidationData(response.data.result);
          const initialExtra = response.data.result?.rooms?.map(_ => ({ selectedChild: false, selectedExtraBed: 0, childFee: 0, extraBedFee: 0 }));
          setRoomChildAndExtraBed(initialExtra);
        }
      }
      fetchPrereserveData();
    }
  }, [preReserveKey]);


  let hotelId;
  if (validationData) {
    hotelId = validationData.accommodationId
  }
  useEffect(() => {
    if (hotelId) {
      const fetchHotelData = async () => {
        const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data) {
          setHotelData(response.data);
          setLoading(false);
        }
      }
      fetchHotelData();
    }
  }, [hotelId]);

  useEffect(() => {

    if (userData) {
      const [code, number] = splitCodeAndNumber(userData.phoneNumber);

      props.form.setFieldsValue({
        [`reserver.gender`]: userData.gender,
        [`reserver.firstName`]: userData.firstName,
        [`reserver.lastName`]: userData.lastName,
        [`reserver.email`]: userData.emailAddress
      });

      if (number && code) {
        props.form.setFieldsValue({
          [`phoneNumber`]: number,
          [`countryCode`]: code
        });
      }

      if (userIsPassenger) {
        props.form.setFieldsValue({
          [`passengers[0].gender`]: userData.gender,
          [`passengers[0].firstName`]: userData.firstName,
          [`passengers[0].lastName`]: userData.lastName,
        });
      }
    }

  }, [userData]);

  let reserveInformation;

  if (validationData) {
    reserveInformation = {
      checkin: validationData.checkin,
      checkout: validationData.checkout,
      duration: moment(validationData.checkout).diff(moment(validationData.checkin), 'days'),
      rooms: validationData.rooms.map(roomItem => ({
        name: roomItem.name,
        board: roomItem.boardCode,
        cancellationPolicyStatus: roomItem.cancellationPolicyStatus,
        bed: roomItem.bed,
        extraBed: roomItem.extraBed,
        pricing: roomItem.pricing,
        maxChildAge: roomItem.maxChildAge,
        maxInfantAge: roomItem.maxInfantAge
      })),
      salePrice: validationData.rooms.reduce((totalPrice, roomItem) => {
        const roomItemPrice = roomItem.pricing.find(
          item => item.type === "Room" && item.ageCategoryType === "ADL"
        )?.amount;
        if (roomItemPrice) {
          return totalPrice + +roomItemPrice
        } else {
          return null;
        }
      }, 0),
      boardPrice: validationData.rooms.reduce((totalPrice, roomItem) => {
        const roomItemPrice = roomItem.pricing.find(
          item => item.type === "RoomBoard" && item.ageCategoryType === "ADL"
        )?.amount;
        if (roomItemPrice) {
          return totalPrice + +roomItemPrice
        } else {
          return null;
        }
      }, 0)
    }
  }

  if (loading) {
    return <Loading fullPage description={Dictionary.GettingPassengerInformation + ' ...'} />;
  }

  const updateRoomChildAndExtraBed = (roomIndex, property, value, fee) => {
    if (roomChildAndExtraBed?.length) {
      setRoomChildAndExtraBed(prevState => {
        const updatedState = [...prevState];
        updatedState[roomIndex][property] = value;
        if (property === "selectedChild") {
          updatedState[roomIndex]["childFee"] = fee;
        }
        if (property === "selectedExtraBed") {
          updatedState[roomIndex]["extraBedFee"] = fee;
        }
        return updatedState
      });
    }
  }

  const updateFirstPassenger = (value, type) => {
    if (userIsPassenger) {
      switch (type) {
        case 'gender':
          props.form.setFieldsValue({
            [`passengers[0].gender`]: value
          });
          break;
        case 'firstName':
          props.form.setFieldsValue({
            [`passengers[0].firstName`]: value
          });
          break;
        case 'lastName':
          props.form.setFieldsValue({
            [`passengers[0].lastName`]: value
          });
          break;
        case 'nationalId':
          props.form.setFieldsValue({
            [`passengers[0].nationalId`]: value
          });
          break;
        default:
          return
      }
    }
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);

        let code = values.countryCode;
        let phone = values.phoneNumber;
        if (phone[0] === "0") {
          phone = phone.substring(1)
        }
        if (code.substring(0, 2) === "00") {
          code = "+" + code.substring(2)
        }
        const params = {
          passengers: values.passengers,
          reserver: {
            ...values.reserver,
            userName: userData ? userData.userName : code + phone,
            phoneNumber: code + phone
          },
          specialRequest: values.specialRequest,
          preReserveKey: preReserveKey
        }

        const reserveResponse = await DomesticHotelPreReserve(params);

        if (reserveResponse.data && reserveResponse.data.result) {
          const id = reserveResponse.data.result.id;
          const username = reserveResponse.data.result.username;
          setLoading(false);

          const mainUrl = reserveResponse.data.result.status === 'Registered' ? "/payment/reserve" : "/domesticHotel/capacity";
          let queries = `?reserveId=${id}&username=${username}`;
          
          let smId;
          let cookies = decodeURIComponent(document.cookie).split(';');
          for (const item of cookies){
            if (item.includes("safarMarketHotelId=")){
              smId=item.split("=")[1];
            }
          }
          const safarmarketId = queryString.parse(props.location.search)?.smid;
          if (safarmarketId){
            smId = safarmarketId;
          }

          if (smId){
            queries += `&smid=${smId}`
          }

          props.history.push(mainUrl+queries);

        } else {
          props.showError({
            visibility: true,
            content: reserveResponse.response?.data?.error?.message || "Internal Error,Please refresh the page",
          });
        }

      }
    });
  };

  const hasDiscount = reserveInformation?.salePrice && reserveInformation.boardPrice && reserveInformation.boardPrice > reserveInformation.salePrice;

  let childCount = 0;
  let childPrice = 0;
  let extraBedCount = 0;
  let extraBedPrice = 0;
  if (roomChildAndExtraBed?.length) {
    childCount = roomChildAndExtraBed.reduce((total, item) => item.selectedChild ? (total + 1) : total, 0);
    childPrice = roomChildAndExtraBed.reduce((total, item) => item.selectedChild ? (total + item.childFee) : total, 0);
    extraBedCount = roomChildAndExtraBed.reduce((total, item) => total + item.selectedExtraBed, 0);
    extraBedPrice = roomChildAndExtraBed.reduce((total, item) => total + (item.selectedExtraBed * item.extraBedFee), 0);
  }

  const prefixSelector = getFieldDecorator('countryCode', {
    initialValue: LAN === 'IR' ? '0098' : LAN === 'AR' ? "00971" : "0047",
  })(
    <select className="form-select" style={{ width: 120 }} dir="ltr">
      {CountryPostalCode.map(item => <option key={item.code} value={item.value}>
        {item.code}
      </option>)}
    </select>,
  );

  return (
    <div className="get-passenger-form-holder">
      <Form onSubmit={SubmitHandler}  >
        <div className="page-padding getpassenger-page">
          <div className="page-container hotel-get-passenger">
            <div className="getPassengers">
              {!!hotelData && (
                <div className="sides-outer-holder">
                  <StickySides
                    main={(
                      <>
                      <div className="bg-white rounded padding-v-15 relative margin-bottom">
                        <div className="padding-h-15 flex-ends-medium middle">
                          <div className="flex-ends middle">
                            <h5 className="no-margin-bottom font-16 md-font-18 margin-end-half semi-bold">
                              {hotelData.HotelCategoryName} {hotelData.HotelName} {hotelData.CityName}
                            </h5>
                            <Rating number={hotelData.HotelRating} />
                          </div>
                          {/* <button type="button" className="link-style-btn button sm-change-room-btn" onClick={this.getBacktoHotelDetails}>
                        تغییر اتاق ها
                      </button> */}
                        </div>
                        <hr className="margin-top-half margin-bottom-half" />
                        <div className="padding-h-15 flex-ends-medium middle">
                          <div className="margin-top-light">
                            <span className="itours-icon location-dark icon-small margin-end-light" />
                            {hotelData.Address}
                          </div>
                          <div className="margin-top-light">
                            <span className="itours-icon moon-dark icon-small margin-end-light" />
                            {reserveInformation?.duration} شب
                          </div>
                        </div>
                      </div>

                      {/* <h5 className="normal-title">
                        {Dictionary.reserverContactInformation}({Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail})
                      </h5> */}
                      <div className="bg-white rounded padding-v-15 relative margin-bottom">
                        <div className="padding-h-15">
                          <h5 className="no-margin-bottom font-16 md-font-18 semi-bold">مشخصات خریدار</h5>
                        </div>
                        <hr className="margin-top-half margin-bottom-half" />
                        <div className="padding-h-15">
                          <Form.Item className="form-group no-margin-bottom">
                            {getFieldDecorator('reserver.gender', {
                              initialValue: true,
                            })(
                              <Radio.Group onChange={e => { updateFirstPassenger(e.target.value, 'gender') }} >
                                <Radio value={true} className="no-margin">{Dictionary.male}</Radio>
                                <Radio value={false}>{Dictionary.female}</Radio>
                              </Radio.Group>,
                            )}
                          </Form.Item>
                          <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                              <Form.Item label={Dictionary.firstName} className="form-group">
                                {getFieldDecorator('reserver.firstName', {
                                  rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                  <Input
                                    className="form-input"
                                    placeholder={Dictionary.firstName}
                                    onChange={e => {
                                      onChangeValidation(e, 'alphabet');
                                      updateFirstPassenger(e.target.value, 'firstName');
                                    }}
                                  />,
                                )}
                              </Form.Item>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <Form.Item label={Dictionary.lastName} className="form-group">
                                {getFieldDecorator('reserver.lastName', {
                                  rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                  <Input
                                    className="form-input"
                                    placeholder={Dictionary.lastName}
                                    onChange={(e) => {
                                      onChangeValidation(e, 'alphabet');
                                      updateFirstPassenger(e.target.value, 'lastName');
                                    }}
                                  />,
                                )}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="float-row">
                            <div className="col-small-12 col-medium-6">
                              <Form.Item className="no-margin form-group" label={Dictionary.email}>
                                {getFieldDecorator('reserver.email', {
                                  rules: [{ required: true, message: Dictionary.enterEmail },
                                  { type: 'email', message: Dictionary.enteredEmailAddressIsNotValid }
                                  ],
                                })(
                                  <Input placeholder={Dictionary.email} className="form-input" />
                                )}
                              </Form.Item>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <Form.Item label={Dictionary.phoneNumber} className="no-margin form-group country-code-pair" >
                                {getFieldDecorator('phoneNumber', {
                                  rules: [
                                    { required: true, message: Dictionary.thisFieldIsRequired },
                                    {
                                      validator: (a, value, c) => {
                                        if (!!value && (value.length < 3 || value.length > 15 || value[0] === "0")) {
                                          return Promise.reject(new Error(Dictionary.enteredPhoneNumberIsNotValid2));
                                        }
                                        return Promise.resolve();
                                      }, message: Dictionary.enteredPhoneNumberIsNotValid2
                                    }
                                  ],
                                })(<Input
                                  addonBefore={prefixSelector}
                                  className="placeholder-right en-input-text"
                                  onChange={(e) => {
                                    onChangeValidation(e, 'number');
                                  }}
                                />)}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="float-row margin-bottom">
                            <div className="col-small-12 col-medium-6">
                              <Form.Item className="no-margin form-group" label={Dictionary.nationalCode}>
                                {getFieldDecorator('reserver.nationalId', {
                                  rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                  <Input
                                    className="form-input"
                                    placeholder={Dictionary.nationalCode}
                                    onChange={e => {
                                      onChangeValidation(e, 'number');
                                      updateFirstPassenger(e.target.value, 'nationalId');
                                    }}
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </div>

                        </div>


                      </div>
                      <div className="get-passenger-form-holder">
                        <div>
                          {reserveInformation?.rooms?.map((roomItem, roomIndex) => <DomesticHotelCheckoutRoomItem
                            key={roomIndex}
                            form={props.form}
                            roomItem={roomItem}
                            roomIndex={roomIndex}
                            reserveInformation={reserveInformation}
                            updateRoomChildAndExtraBed={updateRoomChildAndExtraBed}
                            disableUserIsPassenger={disableUserIsPassenger}
                          />)}

                          <div className="padding-bottom">
                            <Form.Item className="no-margin-bottom">
                              {getFieldDecorator('acceptRuls', {
                                valuePropName: 'checked',
                                rules: [{ required: true, message: Dictionary.youShouldAcceptThis }]
                              })(<Checkbox className="margin-end-half" />)}
                              {LAN === 'IR' ? (
                                <>
                                  <Link to='/terms' className="content-link"> قوانین و مقررات آیتورز </Link> را خوانده ام و با آن موافق هستم.
                                </>
                              ) : (
                                <>
                                  {Dictionary.iHaveReadAndAgreeToThe}
                                  <Link to='/terms' className="content-link" target="_blank">
                                    {Dictionary.termsAndConditions}
                                  </Link>
                                </>
                              )}
                            </Form.Item>

                          </div>
                        </div>
                      </div>

                      <div className="bg-white rounded padding-v-15 relative margin-bottom">
                        <div className="padding-h-15">
                          <h5 className="no-margin-bottom font-16 md-font-18 semi-bold"> {Dictionary.description}</h5>
                        </div>
                        <hr className="margin-top-half margin-bottom-half" />
                        <div className="padding-h-15">
                          <div className="float-row">
                            <div className="col-small-12 col-large-9">
                              <div className="form-group">
                                <p>
                                  {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                                  {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}
                                </p>

                                <Form.Item className="no-margin form-group" >
                                  {getFieldDecorator('specialRequest')(
                                    <textarea className="form-textarea"></textarea>
                                  )}
                                </Form.Item>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* %%remarks */}
                    </>
                    )}
                    aside={(
                      <div className="bg-white card-border card-padding rounded-medium">
                      <div className="sm-check-in-out-wrapper">
                        <strong className="margin-end-light no-wrap">
                          <span className="itours-icon calendar-dark icon-small margin-end-light font-13" />
                          تاریخ ورود و خروج:
                        </strong>
                        <span className="no-wrap">
                          {moment(reserveInformation.checkin).format('jYYYY/jMM/jDD')} - {moment(reserveInformation.checkout).format('jYYYY/jMM/jDD')}
                        </span>
                      </div>

                      <div className="sm-fix-bottom-mobile">
                        <hr className='sm-hr hidden-xsmall hidden-small' />
                        <p className='font-14 text-center margin-bottom-half  hidden-medium hidden-large hidden-xlarge'>
                          <span className='itours-icon icon-small info-icon margin-end-light' />
                          با کلیک روی تایید و ادامه <Link to={'/terms'}> قوانین و مقررات </Link> را پذیرفته اید.
                        </p>
                        <div className="selected-room-footer">


                          {(hasDiscount || !!childCount || !!extraBedCount) && !!reserveInformation.salePrice && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                              {Dictionary.totalPrice}
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                              <Price currency={props.currencyCode}>{reserveInformation.boardPrice}</Price>
                            </b>
                          </div>}

                          {/* {!!childCount && !!childPrice && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                              {Dictionary.child} (x{childCount})
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                              <Price currency={props.currencyCode}>{childPrice}</Price>
                            </b>
                          </div>} */}

                          {!!extraBedCount && !!extraBedPrice && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                              {Dictionary.ExtraBed} (x{extraBedCount})
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                              <Price currency={props.currencyCode}>{extraBedPrice}</Price>
                            </b>
                          </div>}

                          {!!hasDiscount && <div className="clearfix margin-bottom-light">
                            <span className="pull-start font-14">
                              تخفیف سایت
                            </span>
                            <b className="pull-end semi-bold" dir="ltr">
                              <Price currency={props.currencyCode}>{reserveInformation.boardPrice - reserveInformation.salePrice}</Price>
                            </b>
                          </div>}

                          <div>
                            <div className="inline-medium margin-end-half font-12 sm-font-14 xs-margin-bottom-half">مبلغ قابل پرداخت:</div>
                            <b className="new-price inline-medium font-16 sm-font-20">
                              <Price currency={props.currencyCode}>
                                {reserveInformation.salePrice + extraBedPrice}
                                {/* {reserveInformation.salePrice + childPrice + extraBedPrice} */}
                              </Price>
                            </b>
                          </div>


                          <p className='font-14 text-center no-margin-bottom hidden-small hidden-xsmall'>
                            <span className='itours-icon icon-small info-icon margin-end-light' />
                            با کلیک روی تایید و ادامه <Link to={'/terms'}> قوانین و مقررات </Link> را پذیرفته اید.
                          </p>
                          <button className="button red-btn" type="submit" >
                            تایید و ادامه
                            {/* {this.state.reserveLoading &&  
                            <span className="loading-inline-circle margin-start-light" />
                             } */}
                          </button>
                        </div>
                      </div>
                    </div>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  currencyCode: state.UserData.Currency,
  userData: state.UserData.userData,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => { dispatch(showError(param)); },
  setSafarmarketLayout: data => { dispatch(changeSafarmarketLayout(data)) }
});

const DomesticHotelGetPassengerForm = Form.create({ name: 'hotelPrereserveForm' })(DomesticHotelGetPassenger);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomesticHotelGetPassengerForm));