export const UserModel={
    "userName": "",
    "firstName": "",
    "lastName": "",
    "emailAddress": "",
    "isActive": true,
    "displayName": "",
    "lastLoginTime": "",
    "creationTime": "",
    "gender": true,
    "avatar": "",
    "birthDay": "",
    "nationalityId": "",
    "isNewsLater": true,
    "isEmailConfirmed": false,
    "phoneNumber": "",
    "roleNames": [],
    "id": 0
};
export const Reducers={
    HANDLE_ERROR:"HANDLE_ERROR",
    VERIFICATION:"VERIFICATION",
    MESSAGE:"MESSAGE",
    CREATEUSER:"CREATEUSER",
    EDITUSER:"EDITUSER",
    GETCURRENCY:"GETCURRENCY",
    SETCURRENCY: "SETCURRENCY",
    SCROLLABILITY:"SCROLLABILITY" ,
    SAFARMARKET_LAYOUT:"SAFARMARKET_LAYOUT",
    GET_BALANCE:"GET_BALANCE",
    LOGOUT:"LOGOUT"
};
export const Travel={
    "hotel":"hotel",
    "flight":"flight",
    "car":"car",
    "train":"train"
};
export const Sort={
    price:"salePrice",
    name:"name",
    rate:"rating"
};
// export const weekDay=['Sunday','Monday','Tuesday','Wedensday','Thursday','Friday','Saturday'];
export const weekDay=['یکشنبه','دوشنبه','سه شنبه','چهارشنبه','پنجشنبه','جمعه','شنبه'];
Object.freeze(Travel);
Object.freeze(Reducers);
Object.freeze(UserModel);
Object.freeze(Sort);
Object.freeze(weekDay);
// class HotelItem{
//     constructor(){
//         this.address=null;
//         this.features=[];
//         this.geolocation={latitude:0,longitude:0};
//         this.id=0;
//         this.image=null;
//         this.rating=0;
//         this.salePrice=0;
//     }
//
// }
export const FilterType={
    Intersection:"Intersection",
    Union:"Union",
    Like:"Like"
}
export const FilterName={
    rating:"rating",
    features: "features",
    boards:"boards",
    regions:"regions",
    name:"name",
    stop:"stop",
    class:"class",
    airline:"airline",
    departureTime:"departureDateTime",
    returnTime:"arrivalDateTime",
    airlinePrice:"airlinePrice",
    baggage:"baggage",
    extraBaggage:"extraBaggage",
    flightType:"flightType",
    duration:"duration",
    stopTime:"stopTime"
};

export class Filter{
    constructor(){
        this.rating=[];
        this.features=[];
        this.boards=[];
        this.regions=[];
        this.name=""
    }
}
export class FlightFilter{
    constructor(){
        this.stop=[];
        this.cabinClass=[];
        this.airline=[];
        this.departureTime=[];
        this.arrivalTime=[];
        this.airlinePrice=[];
        this.flightType=[];
        this.baggage=[];
        this.baggageDetail=[];
        this.stopTime=[];
        this.duration=[];
    }
}
export class Hotel{
    constructor(){
        this.city={
            id:0,
                name:null,
                code:null
        };
        this.country={
            id:0,
                name:null,
                code:null
        };
        this.date={
            dateFrom:null,
                dateTo:null,
                duration:null
        };
        this.availableFilters=new Filter();
        this.selectedFilters=new Filter();
        this.parameters=[];
        this.selectedHotel=null;
        this.selectedRooms=[];
        this.reserveId=null;
        this.tokenId=null;
        this.reserveHotel=[];
        this.searchURL=null;
        this.safarmarketPixel=undefined;
    }
}
export class Flight{
    constructor(){
        this.airTripType=null;
        this.pricedItineraries=[];
        this.filterShow=new FlightFilter();
        this.filterSelect=new FlightFilter();
        this.flightSelected=null;
        this.reserveId=null;
        this.flightError=null;
        this.reserveFlight=[];
        this.safarmarketPixel=undefined;
        this.directTicketByIdResult=undefined;
        this.getDirectTicketByIdLoading = false;
    }


}

export class Package{
    constructor(){
        this.reservedPackageData=null;
    }
}

export const defaultData= {
    schemaParam: {
        Location:'',
        LocationId: '',
        Adults: [1],
        Children: [],
        Ages: [],
        Checkin: '',
        Checkout: ''
    }
};
export const Payment={
    Registered:'Registered',
    Pending:'Pending',
    Issued:'Issued',
    Canceled:'Canceled',
    Refunded:'Refunded',
    PaidBack:'PaidBack',
    Failed:'Failed',
    PaymentSuccessful:'PaymentSuccessful',
    WebServiceUnsuccessful:'WebServiceUnsuccessful',
    WebServiceCancel:'WebServiceCancel',
    PriceChange:'PriceChange',
    Unavailable:'Unavailable',
    Undefined:'Undefined',
    UnConfirmed:'UnConfirmed',
    InProgress:'InProgress'
}
export const Currency={
  USD:"$",
  NOK:"NOK",
  IRR:"IRR",
  SEK:"SEK",
  DKK:"DKK",
  TRY:"TRY",
  EUR:"€"
};
export const Boards=
{
    RO:{name:"RO",label:"ShowRoom Only",value:1},
    UA:{name:"UA",label:"All Inclusive",value:2},
    BB :{name:"BB",label:"Bed & Breakfast",value:3},
    HB:{name:"HB",label:"Half Board",value:4},
    FB:{name:"FB",label:"Full Board",value:5},
    SS:{name:"SS",label:"Self-Service",value:6},
    AB:{name:"AB",label:"AMERICAN BREAKFAST",value:8},
    AI:{name:"AI",label:"ALL INCLUSIVE",value:9},
    AS:{name:"AS",label:"All Inclusive Premium",value:10},
    BH:{name:"BH",label:"1 BED AND BREAKFAST + 1 HALF BOARD",value:12},
    CB:{name:"CB",label:"CONTINENTAL BREAKFAST",value:13},
    DB:{name:"DB",label:"BUFFET BREAKFAST",value:14},
    DD:{name:"DD",label:"DISNEY DINING",value:15},
    DX:{name:"DX",label:"DISNEY DELUXE DINING",value:16},
    FH:{name:"FH",label:"1 HALF BOARD + 1 FULL BOARD",value:17},
    FL:{name:"FL",label:"DISNEY FB PLUS: + 15 RESTAURANTS",value:18},
    FR:{name:"FR",label:"DISNEY FB PREMIUM: + 20 RESTAURANTS",value:19},
    FS:{name:"FS",label:"DISNEY FB STANDARD: + 5 RESTAURANTS",value:20},
    EB:{name:"EB",label:"ENGLISH BREAKFAST",value:21},
    HH:{name:"HH",label:"DISNEY HB HOTEL: DINNER AT HOTEL",value:22},
    HL:{name:"HL",label:"DISNEY HB PLUS: + 15 RESTAURANTS",value:23},
    HR:{name:"HR",label:"DISNEY HB PREMIUM: + 20 RESTAURANTS",value:24},
    HS:{name:"HS",label:"DISNEY HB STANDARD: + 5 RESTAURANTS",value:25},
    IB:{name:"IB",label:"IRISH BREAKFAST",value:26},
    LB:{name:"LB",label:"LIGHT BREAKFAST",value:27},
    HV:{name:"HV",label:"HALF BOARD WITH BEVERAGES INCLUDED",value:28},
    FV:{name:"FV",label:"FULL BOARD BEVERAGES INCLUDED",value:29},
    QB:{name:"QB",label:"DISNEY QUICK SERVICE BREAKFAST",value:30},
    QM:{name:"QM",label:"DISNEY QUICK SERVICE MEAL",value:31},
    QS:{name:"QS",label:"DISNEY QUICK SERVICE",value:32},
    SB:{name:"SB",label:"SCOTTISH BREAKFAST",value:33},
    SC:{name:"SC",label:"SELF CATERING",value:34},
    AA:{name:"AA",label:"ALL INCLUSIVE SOFT",value:35}
};
Object.freeze(Boards);
Object.freeze(FilterType);
Object.freeze(Payment);
//list of country
export const CountryPostalCode=[
    {value:"0093",code:"Afghanistan (+93)"},
    {value:"00355",code:"Albania (+355)"},
    {value:"00213",code:"Algeria (+213)"},
    {value:"001-684",code:"American Samoa (+1-684)"},
    {value:"00376",code:"Andorra (+376)"},
    {value:"00244",code:"Angola (+244)"},
    {value:"001-264",code:"Anguilla (+1-264)"},
    {value:"00672",code:"Antarctica (+672)"},
    {value:"001-268",code:"Antigua and Barbuda (+1-268)"},
    {value:"0054",code:"Argentina (+54)"},
    {value:"00374",code:"Armenia (+374)"},
    {value:"00297",code:"Aruba (+297)"},
    {value:"0061",code:"Australia (+61)"},
    {value:"0043",code:"Austria (+43)"},
    {value:"00994",code:"Azerbaijan (+994)"},
    {value:"001-242",code:"Bahamas (+1-242)"},
    {value:"00973",code:"Bahrain (+973)"},
    {value:"00880",code:"Bangladesh (+880)"},
    {value:"001-246",code:"Barbados (+1-246)"},
    {value:"00375",code:"Belarus (+375)"},
    {value:"0032",code:"Belgium (+32)"},
    {value:"00501",code:"Belize (+501)"},
    {value:"00229",code:"Benin (+229)"},
    {value:"001-441",code:"Bermuda (+1-441)"},
    {value:"00975",code:"Bhutan (+975)"},
    {value:"00591",code:"Bolivia (+591)"},
    {value:"00387",code:"Bosnia and Herzegovina (+387)"},
    {value:"00267",code:"Botswana (+267)"},
    {value:"0055",code:"Brazil (+55)"},
    {value:"00246",code:"British Indian Ocean Territory (+246)"},
    {value:"001-284",code:"British Virgin Islands (+1-284)"},
    {value:"00673",code:"Brunei (+673)"},
    {value:"00359",code:"Bulgaria (+359)"},
    {value:"00226",code:"Burkina Faso (+226)"},
    {value:"00257",code:"Burundi (+257)"},
    {value:"00855",code:"Cambodia (+855)"},
    {value:"00237",code:"Cameroon (+237)"},
    {value:"001",code:"Canada (+1)"},
    {value:"00238",code:"Cape Verde (+238)"},
    {value:"001-345",code:"Cayman Islands (+1-345)"},
    {value:"00236",code:"Central African Republic (+236)"},
    {value:"00235",code:"Chad (+235)"},
    {value:"0056",code:"Chile (+56)"},
    {value:"0086",code:"China (+86)"},
    {value:"0061",code:"Christmas Island (+61)"},
    {value:"0061",code:"Cocos Islands (+61)"},
    {value:"0057",code:"Colombia (+57)"},
    {value:"00269",code:"Comoros (+269)"},
    {value:"00682",code:"Cook Islands (+682)"},
    {value:"00506",code:"Costa Rica (+506)"},
    {value:"00385",code:"Croatia (+385)"},
    {value:"0053",code:"Cuba (+53)"},
    {value:"00599",code:"Curacao (+599)"},
    {value:"00357",code:"Cyprus (+357)"},
    {value:"00420",code:"Czech Republic (+420)"},
    {value:"00243",code:"Democratic Republic of the Congo (+243)"},
    {value:"0045",code:"Denmark (+45)"},
    {value:"00253",code:"Djibouti (+253)"},
    {value:"001-767",code:"Dominica (+1-767)"},
    {value:"001-809, 1-829, 1-849",code:"Dominican Republic (+1-809, 1-829, 1-849)"},
    {value:"00670",code:"East Timor (+670)"},
    {value:"00593",code:"Ecuador (+593)"},
    {value:"0020",code:"Egypt (+20)"},
    {value:"00503",code:"El Salvador (+503)"},
    {value:"00240",code:"Equatorial Guinea (+240)"},
    {value:"00291",code:"Eritrea (+291)"},
    {value:"00372",code:"Estonia (+372)"},
    {value:"00251",code:"Ethiopia (+251)"},
    {value:"00500",code:"Falkland Islands (+500)"},
    {value:"00298",code:"Faroe Islands (+298)"},
    {value:"00679",code:"Fiji (+679)"},
    {value:"00358",code:"Finland (+358)"},
    {value:"0033",code:"France (+33)"},
    {value:"00689",code:"French Polynesia (+689)"},
    {value:"00241",code:"Gabon (+241)"},
    {value:"00220",code:"Gambia (+220)"},
    {value:"00995",code:"Georgia (+995)"},
    {value:"0049",code:"Germany (+49)"},
    {value:"00233",code:"Ghana (+233)"},
    {value:"00350",code:"Gibraltar (+350)"},
    {value:"0030",code:"Greece (+30)"},
    {value:"00299",code:"Greenland (+299)"},
    {value:"001-473",code:"Grenada (+1-473)"},
    {value:"001-671",code:"Guam (+1-671)"},
    {value:"00502",code:"Guatemala (+502)"},
    {value:"0044-1481",code:"Guernsey (+44-1481)"},
    {value:"00224",code:"Guinea (+224)"},
    {value:"00245",code:"Guinea-Bissau (+245)"},
    {value:"00592",code:"Guyana (+592)"},
    {value:"00509",code:"Haiti (+509)"},
    {value:"00504",code:"Honduras (+504)"},
    {value:"00852",code:"Hong Kong (+852)"},
    {value:"0036",code:"Hungary (+36)"},
    {value:"00354",code:"Iceland (+354)"},
    {value:"0091",code:"India (+91)"},
    {value:"0062",code:"Indonesia (+62)"},
    {value:"0098",code:"Iran (+98)"},
    {value:"00964",code:"Iraq (+964)"},
    {value:"00353",code:"Ireland (+353)"},
    {value:"0039",code:"Italy (+39)"},
    {value:"00225",code:"Ivory Coast (+225)"},
    {value:"001-876",code:"Jamaica (+1-876)"},
    {value:"0081",code:"Japan (+81)"},
    {value:"0044-1534",code:"Jersey (+44-1534)"},
    {value:"00962",code:"Jordan (+962)"},
    {value:"007",code:"Kazakhstan (+7)"},
    {value:"00254",code:"Kenya (+254)"},
    {value:"00686",code:"Kiribati (+686)"},
    {value:"00383",code:"Kosovo (+383)"},
    {value:"00965",code:"Kuwait (+965)"},
    {value:"00996",code:"Kyrgyzstan (+996)"},
    {value:"00856",code:"Laos (+856)"},
    {value:"00371",code:"Latvia (+371)"},
    {value:"00961",code:"Lebanon (+961)"},
    {value:"00266",code:"Lesotho (+266)"},
    {value:"00231",code:"Liberia (+231)"},
    {value:"00218",code:"Libya (+218)"},
    {value:"00423",code:"Liechtenstein (+423)"},
    {value:"00370",code:"Lithuania (+370)"},
    {value:"00352",code:"Luxembourg (+352)"},
    {value:"00853",code:"Macau (+853)"},
    {value:"00389",code:"Macedonia (+389)"},
    {value:"00261",code:"Madagascar (+261)"},
    {value:"00265",code:"Malawi (+265)"},
    {value:"0060",code:"Malaysia (+60)"},
    {value:"00960",code:"Maldives (+960)"},
    {value:"00223",code:"Mali (+223)"},
    {value:"00356",code:"Malta (+356)"},
    {value:"00692",code:"Marshall Islands (+692)"},
    {value:"00222",code:"Mauritania (+222)"},
    {value:"00230",code:"Mauritius (+230)"},
    {value:"00262",code:"Mayotte (+262)"},
    {value:"0052",code:"Mexico (+52)"},
    {value:"00691",code:"Micronesia (+691)"},
    {value:"00373",code:"Moldova (+373)"},
    {value:"00377",code:"Monaco (+377)"},
    {value:"00976",code:"Mongolia (+976)"},
    {value:"00382",code:"Montenegro (+382)"},
    {value:"001-664",code:"Montserrat (+1-664)"},
    {value:"00212",code:"Morocco (+212)"},
    {value:"00258",code:"Mozambique (+258)"},
    {value:"0095",code:"Myanmar (+95)"},
    {value:"00264",code:"Namibia (+264)"},
    {value:"00674",code:"Nauru (+674)"},
    {value:"00977",code:"Nepal (+977)"},
    {value:"0031",code:"Netherlands (+31)"},
    {value:"00599",code:"Netherlands Antilles (+599)"},
    {value:"00687",code:"New Caledonia (+687)"},
    {value:"0064",code:"New Zealand (+64)"},
    {value:"00505",code:"Nicaragua (+505)"},
    {value:"00227",code:"Niger (+227)"},
    {value:"00234",code:"Nigeria (+234)"},
    {value:"00683",code:"Niue (+683)"},
    {value:"00850",code:"North Korea (+850)"},
    {value:"001-670",code:"Northern Mariana Islands (+1-670)"},
    {value:"0047",code:"Norway (+47)"},
    {value:"00968",code:"Oman (+968)"},
    {value:"0092",code:"Pakistan (+92)"},
    {value:"00680",code:"Palau (+680)"},
    {value:"00970",code:"Palestine (+970)"},
    {value:"00507",code:"Panama (+507)"},
    {value:"00675",code:"Papua New Guinea (+675)"},
    {value:"00595",code:"Paraguay (+595)"},
    {value:"0051",code:"Peru (+51)"},
    {value:"0063",code:"Philippines (+63)"},
    {value:"0064",code:"Pitcairn (+64)"},
    {value:"0048",code:"Poland (+48)"},
    {value:"00351",code:"Portugal (+351)"},
    {value:"001-787,1-939",code:"Puerto Rico (+1-787,1-939)"},
    {value:"00974",code:"Qatar (+974)"},
    {value:"00242",code:"Republic of the Congo (+242)"},
    {value:"00262",code:"Reunion (+262)"},
    {value:"0040",code:"Romania (+40)"},
    {value:"007",code:"Russia (+7)"},
    {value:"00250",code:"Rwanda (+250)"},
    {value:"00590",code:"Saint Barthelemy (+590)"},
    {value:"00290",code:"Saint Helena (+290)"},
    {value:"001-869",code:"Saint Kitts and Nevis (+1-869)"},
    {value:"001-758",code:"Saint Lucia (+1-758)"},
    {value:"00590",code:"Saint Martin (+590)"},
    {value:"00508",code:"Saint Pierre and Miquelon (+508)"},
    {value:"001-784",code:"Saint Vincent and the Grenadines (+1-784)"},
    {value:"00685",code:"Samoa (+685)"},
    {value:"00378",code:"San Marino (+378)"},
    {value:"00966",code:"Saudi Arabia (+966)"},
    {value:"00221",code:"Senegal (+221)"},
    {value:"00381",code:"Serbia (+381)"},
    {value:"00248",code:"Seychelles (+248)"},
    {value:"00232",code:"Sierra Leone (+232)"},
    {value:"0065",code:"Singapore (+65)"},
    {value:"001-721",code:"Sint Maarten (+1-721)"},
    {value:"00421",code:"Slovakia (+421)"},
    {value:"00386",code:"Slovenia (+386)"},
    {value:"00677",code:"Solomon Islands (+677)"},
    {value:"00252",code:"Somalia (+252)"},
    {value:"0027",code:"South Africa (+27)"},
    {value:"0082",code:"South Korea (+82)"},
    {value:"00211",code:"South Sudan (+211)"},
    {value:"0034",code:"Spain (+34)"},
    {value:"0094",code:"Sri Lanka (+94)"},
    {value:"00249",code:"Sudan (+249)"},
    {value:"00597",code:"Suriname (+597)"},
    {value:"00268",code:"Swaziland (+268)"},
    {value:"0046",code:"Sweden (+46)"},
    {value:"0041",code:"Switzerland (+41)"},
    {value:"00963",code:"Syria (+963)"},
    {value:"00886",code:"Taiwan (+886)"},
    {value:"00992",code:"Tajikistan (+992)"},
    {value:"00255",code:"Tanzania (+255)"},
    {value:"0066",code:"Thailand (+66)"},
    {value:"00228",code:"Togo (+228)"},
    {value:"00690",code:"Tokelau (+690)"},
    {value:"00676",code:"Tonga (+676)"},
    {value:"001-868",code:"Trinidad and Tobago (+1-868)"},
    {value:"00216",code:"Tunisia (+216)"},
    {value:"0090",code:"Turkey (+90)"},
    {value:"00993",code:"Turkmenistan (+993)"},
    {value:"001-649",code:"Turks and Caicos Islands (+1-649)"},
    {value:"00688",code:"Tuvalu (+688)"},
    {value:"001-340",code:"US Virgin Islands (+1-340)"},
    {value:"00256",code:"Uganda (+256)"},
    {value:"00380",code:"Ukraine (+380)"},
    {value:"00971",code:"United Arab Emirates (+971)"},
    {value:"0044",code:"United Kingdom (+44)"},
    {value:"001",code:"United States (+1)"},
    {value:"00598",code:"Uruguay (+598)"},
    {value:"00998",code:"Uzbekistan (+998)"},
    {value:"00678",code:"Vanuatu (+678)"},
    {value:"00379",code:"Vatican (+379)"},
    {value:"0058",code:"Venezuela (+58)"},
    {value:"0084",code:"Vietnam (+84)"},
    {value:"00681",code:"Wallis and Futuna (+681)"},
    {value:"00212",code:"Western Sahara (+212)"},
    {value:"00967",code:"Yemen (+967)"},
    {value:"00260",code:"Zambia (+260)"},
    {value:"00263",code:"Zimbabwe (+263)"},
    {value:"0044-1624",code:"isle of man (+44-1624)"},
    {value:"00239",code:"sao tome and principe (+239)"}
].sort((a, b) => {
    let x = a.code.toLowerCase();
    let y = b.code.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
export const CountryCode=[
    {"value":"AF","code":"Afghanistan"},
    {"value":"AX","code":"land Islands"},
    {"value":"AL","code":"Albania"},
    {"value":"DZ","code":"Algeria"},
    {"value":"AS","code":"American Samoa"},
    {"value":"AD","code":"Andorra"},
    {"value":"AO","code":"Angola"},
    {"value":"AI","code":"Anguilla"},
    {"value":"AQ","code":"Antarctica"},
    {"value":"AG","code":"Antigua and Barbuda"},
    {"value":"AR","code":"Argentina"},
    {"value":"AM","code":"Armenia"},
    {"value":"AW","code":"Aruba"},
    {"value":"AU","code":"Australia"},
    {"value":"AT","code":"Austria"},
    {"value":"AZ","code":"Azerbaijan"},
    {"value":"BS","code":"Bahamas"},
    {"value":"BH","code":"Bahrain"},
    {"value":"BD","code":"Bangladesh"},
    {"value":"BB","code":"Barbados"},
    {"value":"BY","code":"Belarus"},
    {"value":"BE","code":"Belgium"},
    {"value":"BZ","code":"Belize"},
    {"value":"BJ","code":"Benin"},
    {"value":"BM","code":"Bermuda"},
    {"value":"BT","code":"Bhutan"},
    {"value":"BO","code":"Bolivia (Plurinational State of)"},
    {"value":"BQ","code":"Bonaire, Sint Eustatius and Saba"},
    {"value":"BA","code":"Bosnia and Herzegovina"},
    {"value":"BW","code":"Botswana"},
    {"value":"BV","code":"Bouvet Island"},
    {"value":"BR","code":"Brazil"},
    {"value":"IO","code":"British Indian Ocean Territory"},
    {"value":"BN","code":"Brunei Darussalam"},
    {"value":"BG","code":"Bulgaria"},
    {"value":"BF","code":"Burkina Faso"},
    {"value":"BI","code":"Burundi"},
    {"value":"CV","code":"Cabo Verde"},
    {"value":"KH","code":"Cambodia"},
    {"value":"CM","code":"Cameroon"},
    {"value":"CA","code":"Canada"},
    {"value":"KY","code":"Cayman Islands"},
    {"value":"CF","code":"Central African Republic"},
    {"value":"TD","code":"Chad"},
    {"value":"CL","code":"Chile"},
    {"value":"CN","code":"China"},
    {"value":"CX","code":"Christmas Island"},
    {"value":"CC","code":"Cocos (Keeling) Islands"},
    {"value":"CO","code":"Colombia"},
    {"value":"KM","code":"Comoros"},
    {"value":"CG","code":"Congo"},
    {"value":"CD","code":"Congo (Democratic Republic of the)"},
    {"value":"CK","code":"Cook Islands"},
    {"value":"CR","code":"Costa Rica"},
    {"value":"CI","code":"Cote d'Ivoire"},
    {"value":"HR","code":"Croatia"},
    {"value":"CU","code":"Cuba"},
    {"value":"CW","code":"Curacao"},
    {"value":"CY","code":"Cyprus"},
    {"value":"CZ","code":"Czech Republic"},
    {"value":"DK","code":"Denmark"},
    {"value":"DJ","code":"Djibouti"},
    {"value":"DM","code":"Dominica"},
    {"value":"DO","code":"Dominican Republic"},
    {"value":"EC","code":"Ecuador"},
    {"value":"EG","code":"Egypt"},
    {"value":"SV","code":"El Salvador"},
    {"value":"GQ","code":"Equatorial Guinea"},
    {"value":"ER","code":"Eritrea"},
    {"value":"EE","code":"Estonia"},
    {"value":"ET","code":"Ethiopia"},
    {"value":"FK","code":"Falkland Islands (Malvinas)"},
    {"value":"FO","code":"Faroe Islands"},
    {"value":"FJ","code":"Fiji"},
    {"value":"FI","code":"Finland"},
    {"value":"FR","code":"France"},
    {"value":"GF","code":"French Guiana"},
    {"value":"PF","code":"French Polynesia"},
    {"value":"TF","code":"French Southern Territories"},
    {"value":"GA","code":"Gabon"},
    {"value":"GM","code":"Gambia"},
    {"value":"GE","code":"Georgia"},
    {"value":"DE","code":"Germany"},
    {"value":"GH","code":"Ghana"},
    {"value":"GI","code":"Gibraltar"},
    {"value":"GR","code":"Greece"},
    {"value":"GL","code":"Greenland"},
    {"value":"GD","code":"Grenada"},
    {"value":"GP","code":"Guadeloupe"},
    {"value":"GU","code":"Guam"},
    {"value":"GT","code":"Guatemala"},
    {"value":"GG","code":"Guernsey"},
    {"value":"GN","code":"Guinea"},
    {"value":"GW","code":"Guinea-Bissau"},
    {"value":"GY","code":"Guyana"},
    {"value":"HT","code":"Haiti"},
    {"value":"HM","code":"Heard Island and McDonald Islands"},
    {"value":"VA","code":"Holy See"},
    {"value":"HN","code":"Honduras"},
    {"value":"HK","code":"Hong Kong"},
    {"value":"HU","code":"Hungary"},
    {"value":"IS","code":"Iceland"},
    {"value":"IN","code":"India"},
    {"value":"ID","code":"Indonesia"},
    {"value":"IR","code":"Iran"},
    {"value":"IQ","code":"Iraq"},
    {"value":"IE","code":"Ireland"},
    {"value":"IM","code":"Isle of Man"},
    {"value":"IT","code":"Italy"},
    {"value":"JM","code":"Jamaica"},
    {"value":"JP","code":"Japan"},
    {"value":"JE","code":"Jersey"},
    {"value":"JO","code":"Jordan"},
    {"value":"KZ","code":"Kazakhstan"},
    {"value":"KE","code":"Kenya"},
    {"value":"KI","code":"Kiribati"},
    {"value":"KP","code":"Korea (Democratic People's Republic of)"},
    {"value":"KR","code":"Korea (Republic of)"},
    {"value":"KW","code":"Kuwait"},
    {"value":"KG","code":"Kyrgyzstan"},
    {"value":"LA","code":"Lao People's Democratic Republic"},
    {"value":"LV","code":"Latvia"},
    {"value":"LB","code":"Lebanon"},
    {"value":"LS","code":"Lesotho"},
    {"value":"LR","code":"Liberia"},
    {"value":"LY","code":"Libya"},
    {"value":"LI","code":"Liechtenstein"},
    {"value":"LT","code":"Lithuania"},
    {"value":"LU","code":"Luxembourg"},
    {"value":"MO","code":"Macao"},
    {"value":"MK","code":"Macedonia (the former Yugoslav Republic of)"},
    {"value":"MG","code":"Madagascar"},
    {"value":"MW","code":"Malawi"},
    {"value":"MY","code":"Malaysia"},
    {"value":"MV","code":"Maldives"},
    {"value":"ML","code":"Mali"},
    {"value":"MT","code":"Malta"},
    {"value":"MH","code":"Marshall Islands"},
    {"value":"MQ","code":"Martinique"},
    {"value":"MR","code":"Mauritania"},
    {"value":"MU","code":"Mauritius"},
    {"value":"YT","code":"Mayotte"},
    {"value":"MX","code":"Mexico"},
    {"value":"FM","code":"Micronesia (Federated States of)"},
    {"value":"MD","code":"Moldova (Republic of)"},
    {"value":"MC","code":"Monaco"},
    {"value":"MN","code":"Mongolia"},
    {"value":"ME","code":"Montenegro"},
    {"value":"MS","code":"Montserrat"},
    {"value":"MA","code":"Morocco"},
    {"value":"MZ","code":"Mozambique"},
    {"value":"MM","code":"Myanmar"},
    {"value":"NA","code":"Namibia"},
    {"value":"NR","code":"Nauru"},
    {"value":"NP","code":"Nepal"},
    {"value":"NL","code":"Netherlands"},
    {"value":"NC","code":"New Caledonia"},
    {"value":"NZ","code":"New Zealand"},
    {"value":"NI","code":"Nicaragua"},
    {"value":"NE","code":"Niger"},
    {"value":"NG","code":"Nigeria"},
    {"value":"NU","code":"Niue"},
    {"value":"NF","code":"Norfolk Island"},
    {"value":"MP","code":"Northern Mariana Islands"},
    {"value":"NO","code":"Norway"},
    {"value":"OM","code":"Oman"},
    {"value":"PK","code":"Pakistan"},
    {"value":"PW","code":"Palau"},
    {"value":"PS","code":"Palestine, State of"},
    {"value":"PA","code":"Panama"},
    {"value":"PG","code":"Papua New Guinea"},
    {"value":"PY","code":"Paraguay"},
    {"value":"PE","code":"Peru"},
    {"value":"PH","code":"Philippines"},
    {"value":"PN","code":"Pitcairn"},
    {"value":"PL","code":"Poland"},
    {"value":"PT","code":"Portugal"},
    {"value":"PR","code":"Puerto Rico"},
    {"value":"QA","code":"Qatar"},
    {"value":"RE","code":"Réunion"},
    {"value":"RO","code":"Romania"},
    {"value":"RU","code":"Russian Federation"},
    {"value":"RW","code":"Rwanda"},
    {"value":"BL","code":"Saint Barthelemy"},
    {"value":"SH","code":"Saint Helena, Ascension and Tristan da Cunha"},
    {"value":"KN","code":"Saint Kitts and Nevis"},
    {"value":"LC","code":"Saint Lucia"},
    {"value":"MF","code":"Saint Martin (French part)"},
    {"value":"PM","code":"Saint Pierre and Miquelon"},
    {"value":"VC","code":"Saint Vincent and the Grenadines"},
    {"value":"WS","code":"Samoa"},
    {"value":"SM","code":"San Marino"},
    {"value":"ST","code":"Sao Tome and Principe"},
    {"value":"SA","code":"Saudi Arabia"},
    {"value":"SN","code":"Senegal"},
    {"value":"RS","code":"Serbia"},
    {"value":"SC","code":"Seychelles"},
    {"value":"SL","code":"Sierra Leone"},
    {"value":"SG","code":"Singapore"},
    {"value":"SX","code":"Sint Maarten (Dutch part)"},
    {"value":"SK","code":"Slovakia"},
    {"value":"SI","code":"Slovenia"},
    {"value":"SB","code":"Solomon Islands"},
    {"value":"SO","code":"Somalia"},
    {"value":"ZA","code":"South Africa"},
    {"value":"GS","code":"South Georgia and the South Sandwich Islands"},
    {"value":"SS","code":"South Sudan"},
    {"value":"ES","code":"Spain"},
    {"value":"LK","code":"Sri Lanka"},
    {"value":"SD","code":"Sudan"},
    {"value":"SR","code":"Suriname"},
    {"value":"SJ","code":"Svalbard and Jan Mayen"},
    {"value":"SZ","code":"Swaziland"},
    {"value":"SE","code":"Sweden"},
    {"value":"CH","code":"Switzerland"},
    {"value":"SY","code":"Syrian Arab Republic"},
    {"value":"TW","code":"Taiwan, Province of China[a]"},
    {"value":"TJ","code":"Tajikistan"},
    {"value":"TZ","code":"Tanzania, United Republic of"},
    {"value":"TH","code":"Thailand"},
    {"value":"TL","code":"Timor-Leste"},
    {"value":"TG","code":"Togo"},
    {"value":"TK","code":"Tokelau"},
    {"value":"TO","code":"Tonga"},
    {"value":"TT","code":"Trinidad and Tobago"},
    {"value":"TN","code":"Tunisia"},
    {"value":"TR","code":"Turkey"},
    {"value":"TM","code":"Turkmenistan"},
    {"value":"TC","code":"Turks and Caicos Islands"},
    {"value":"TV","code":"Tuvalu"},
    {"value":"UG","code":"Uganda"},
    {"value":"UA","code":"Ukraine"},
    {"value":"AE","code":"United Arab Emirates"},
    {"value":"GB","code":"United Kingdom of Great Britain and Northern Ireland"},
    {"value":"US","code":"United States of America"},
    {"value":"UM","code":"United States Minor Outlying Islands"},
    {"value":"UY","code":"Uruguay"},
    {"value":"UZ","code":"Uzbekistan"},
    {"value":"VU","code":"Vanuatu"},
    {"value":"VE","code":"Venezuela (Bolivarian Republic of)"},
    {"value":"VN","code":"Viet Nam"},
    {"value":"VG","code":"Virgin Islands (British)"},
    {"value":"VI","code":"Virgin Islands (U.S.)"},
    {"value":"WF","code":"Wallis and Futuna"},
    {"value":"EH","code":"Western Sahara"},
    {"value":"YE","code":"Yemen"},
    {"value":"ZM","code":"Zambia"},
    {"value":"ZW","code":"Zimbabwe"}
];