import React from 'react';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import _ from 'lodash';

import Rate from "../Common/Rate";
import { setFilter } from "../../Actions/HotelAction";
import { FilterName } from "../../Enum/Models";
import LayoutConfig from '../../Config/layoutConfig';

const FilterForm = props => {
    const { Dictionary, availableFilters, setFilter } = props;
    return (
        <>
            <strong className={`filter-header ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? "" : "margin-top"}`}>{Dictionary.filters}</strong>
            <div className='padding-h-10'>
                <div className="sidebar-item">
                    <h5 className="normal-title"><span>{Dictionary.searchByName}</span></h5>
                    <div className="form-group has-fake-magnifirer-icon">
                        <input
                            className="form-input placeholder-right"
                            placeholder={Dictionary.enterHotelName}
                            type="text"
                            onChange={e => { setFilter({ value: e.target.value, filterType: FilterName.name }) }}
                        />
                    </div>
                </div>
                <div className="sidebar-item">
                    <h5 className="normal-title"> <span className='okr-bg'>{Dictionary.starRating}</span> </h5>
                    <div className="filter-content">
                        <Checkbox.Group onChange={value => { setFilter({ value, filterType: FilterName.rating }) }}>
                            {availableFilters.rating.sort().map(item => (
                                <div key={item}>
                                    <Checkbox value={item} className="antd-custom-checkbox hotel-rating-checkbox" >
                                        <Rate number={parseFloat(item)} />
                                    </Checkbox>
                                </div>
                            ))}
                        </Checkbox.Group>
                    </div>
                </div>
                {!!availableFilters.features?.length && <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.facilities}</span></h5>
                    <Checkbox.Group onChange={value => { setFilter({ value, filterType: FilterName.features }) }}>
                        {_.sortBy(availableFilters.features, ["name"]).map(item => (
                            <div key={item.name}>
                                <Checkbox value={item.keyword} className="antd-custom-checkbox" >
                                    {item.name}
                                </Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>}

                <div className="sidebar-item">
                    <h5 className="normal-title"> <span className='okr-bg'>{Dictionary.board}</span> </h5>
                    <Checkbox.Group onChange={value => { setFilter({ value, filterType: FilterName.boards }) }}>
                        {_.sortBy(availableFilters.boards, ["name"]).map(item => (
                            <div key={item.name}>
                                <Checkbox value={item.code} className="antd-custom-checkbox" >
                                    {item.name}
                                </Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>

                {!!availableFilters.regions?.length && <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.area}</span></h5>
                    <Checkbox.Group onChange={value => { setFilter({ value, filterType: FilterName.regions }) }}>
                        {_.sortBy(availableFilters.regions, ["name"]).map(item => (
                            <div key={item.id}>
                                <Checkbox value={item.id} className="antd-custom-checkbox" >
                                    {item.name}
                                </Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>}
            </div>
        </>
    )
}

const dispatchToProps = (dispatch) => ({
    setFilter: (data) => dispatch(setFilter(data))
})

const stateToProps = (state) => ({
    availableFilters: state.HotelSearch.availableFilters,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});

export default connect(stateToProps, dispatchToProps)(FilterForm);